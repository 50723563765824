import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC} from "./CommonReducer";
import ApiService from "../../api/api";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {gaEvents} from "../../gaEvents";
import {setStartRuleSettingProject} from "./AuthReducer";

const SAVE_USER_DATA_STEP_ONE = "SAVE_USER_DATA_STEP_ONE";
const VARIFICATION_USER = "VARIFICATION_USER";
const AUTORIZED_USER_DATA = "AUTORIZED_USER_DATA";
const SUCCESSFUL_REGISTER_NEW_CLIENT = "SUCCESSFUL_REGISTER_NEW_CLIENT";
const SET_FIRM_DT = "SET_FIRM_DT";
export const AUTH_CLIENTS_FIRMS = 'AUTH/CLIENTS_FIRMS';
const api = new ApiService();

let initialState = {
    isLogin: false,
    userData: {
        email: '',
        phone: '',
        pass: '',
        name: ''
    },
    verificationUser: {
        email_autorization: null,
        is_client: null,
        is_manager: null,
        this_firm: null,
        user_autorization: null,
        client_id: null,
        needPassword: null,
        registered: null
    },
    autorizedUser: {
        autorized: null,
        email_autorization: null,
        is_client: null,
        is_manager: null,
        this_firm_id: null,
        this_firm_name: null,
        user_autorization: null
    },
    successfulRegister: false,
    firmDT: {count: '', data: {}},
    client_firms: {
        isLoading: false,
        isError: false,
        error: null,
        entry_firm: null
    }

};


const RegisterReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CLIENTS_FIRMS: {
            return {
                ...state,
                client_firms: {
                    ...state.client_firms,
                    ...action.payload
                }
            }
        }
        case SAVE_USER_DATA_STEP_ONE: {
            return {
                ...state,
                userData: {...action.payload}
            }
            break;
        }
        case VARIFICATION_USER: {
            return {
                ...state,
                verificationUser: {...action.payload}
            }
            break;
        }
        case AUTORIZED_USER_DATA: {
            return {
                ...state,
                autorizedUser: {...action.payload}
            }
        }
        case SUCCESSFUL_REGISTER_NEW_CLIENT: {
            return {
                ...state,
                successfulRegister: {...action.payload}
            }
        }
        case SET_FIRM_DT: {
            return {
                ...state,
                firmDT: {...action.paylaod}
            }
        }
        default:
            return state
    }
}
const setFirmDT = (paylaod) => {
    return {
        type: SET_FIRM_DT,
        paylaod
    }
}
const verificationUserAC = (payload) => {
    return {
        type: VARIFICATION_USER,
        payload
    }
}
const saveVarificationUserDataAC = (payload) => {
    return {
        type: SAVE_USER_DATA_STEP_ONE,
        payload
    }
}
const autorizedUserDataAC = (payload) => {
    return {
        type: AUTORIZED_USER_DATA,
        payload
    }
}
const successfulRegisterAC = (payload) => {
    return {
        type: SUCCESSFUL_REGISTER_NEW_CLIENT,
        payload
    }
}
export const getFromUriClientsFirmsAC = (payload) => {
    return {
        type: AUTH_CLIENTS_FIRMS,
        payload: payload
    }
}

export const onSetFirmDT = (key) => async dispatch => {
    try {
        const response = await api.getRegisterFirmName(key);
        dispatch(setFirmDT(response));
        if (response?.hasOwnProperty('data') && Array.isArray(response?.data) && response?.data?.length) {
            let data_settings = {
                ...response?.data?.[0],
                telegram: response?.data?.[0]?.telegramm ?? '',
                firm: response?.data?.[0]?.firm_id ?? ''
            }
            setStartRuleSettingProject(data_settings, dispatch);
        } else {
            setStartRuleSettingProject({}, dispatch);
            dispatch(changeAlertStatusAC(true, `  ${getTranslateMessageAPI("Произошел сбой, попробуйте позже!")}`));

        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e.message)
    }
}
//** firm_id?, payload { required phone, email} */
export const verificationUserThunc = (data, call) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Проверяем  данные...')));
    api._getVarificationUser(data).then(res => {
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            setTimeout(() => dispatch(changeAlertStatusAC(false)), 2400)

        } else {
            dispatch(verificationUserAC(res));
            dispatch(saveVarificationUserDataAC(data.payload))
            if (call) call();
        }
        dispatch(changeLoaderStatusAC(false));
    }).catch(e => {
        dispatch(changeLoaderStatusAC(false));
    })
}
//** payload { required phone, email, pass}*/
export const reqisterUserThunk = (data, callRegister = false) => dispatch => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Проверяем  данные...')));
    api._sendRegisterUser(data).then(res => {
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));

        } else {
            gaEvents.signUp();
            dispatch(saveVarificationUserDataAC(data.payload));
            dispatch(autorizedUserDataAC(res));
            dispatch(successfulRegisterAC({successfulRegister: true}))
        }
        dispatch(changeLoaderStatusAC(false));

    }).catch(e => {
        dispatch(changeLoaderStatusAC(false));
    })
}
export const resetRegisterPasswordThunk = (client_id, data) => async dispatch => {

    try {
        dispatch(changeLoaderStatusAC(true));
        let response = await api._resetRegisterPassword(client_id, data);
        dispatch(changeLoaderStatusAC(false));
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            return
        }
        if (response.message) {
            dispatch(changeAlertStatusAC(true, response.message, true));
        }
    } catch (e) {
        let msg =
            dispatch(changeAlertStatusAC(true, msg, true));
        dispatch(changeLoaderStatusAC(false));
    }
    // api._resetRegisterPassword(client_id, data).then(res => {
    //     if (api._errors) {
    //         dispatch(changeAlertStatusAC(true, api._getErrors()));
    //         // dispatch(changeAlertStatusAC(true, 'Пароль клиента успешно обновлен и отправлен ему на почту!'));
    //
    //     } else if (res.message) {
    //         dispatch(changeAlertStatusAC(true, res.message, true));
    //     }
    // }).catch(e => {
    //     dispatch(changeLoaderStatusAC(false));
    // })
}
export const autorizedFirmRegisterThunk = (data, callError) => dispatch => {

    api._autorizedFirmRegister(data).then(res => {
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            if (callError) callError();
        }
    }).catch(e => {
        dispatch(changeLoaderStatusAC(false));
    })
}

export const ResetAllData = () => dispatch => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(verificationUserAC({}))
    dispatch(saveVarificationUserDataAC({}))
    dispatch(autorizedUserDataAC({}))
    dispatch(changeLoaderStatusAC(false));
}


export default RegisterReducer;
