
export const ASSOCIATION_OWNER_DROP ={
    1: {
        val: "firm",
        type: "m", id: 1, name:"m2"
    },
    7: {
        val: "client",
        type: "sheet",
        id: 7, name:"Листами"
    }
}

export const OWNER_DROP_TYPE = ["firm", "client"];


export const getDropOwnerTypeOfMaterial = (calcTypeWithStock, resToClientMaterial) => {
    return (calcTypeWithStock === "m" || (calcTypeWithStock === "sheet" && resToClientMaterial == 0))
        ? "firm"
        : (calcTypeWithStock === "sheet" && resToClientMaterial == 1) ? 'client' : null;
}
