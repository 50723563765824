import React from 'react';
import {findErrorResponse, getErrorByResponse} from "./utils_api";
import {LS_CLIENT_TOKEN, LS_USER_TOKEN} from "../constants";
import {watcherApiPauseSite} from "./watcher_api";

class MainApi extends React.Component {

    //TODO: ссылка под замену (АПИ)
    constructor() {
        super();
        const checkHosts = ['newgo', 'ifurn', 'localhost', 'ifurn.local']
        this._urlDefApi = 'api.ifurn.pro/api';
        this._urlIfurnApi = 'api.ifurn.pro';
        this._urlDefGl = 'gl.ifurn.pro';
        this._urlDefCrm = 'crm.ifurn.pro';
        this._urlDefMain = 'go.ifurn.pro';
        this._openFileDefApi = 'd.ifurn.pro?';
        let hostname = window.location.hostname,
            delimiter = hostname.match('-'),
            protocol = window.location.protocol;
        this._hostName = protocol + "//" + hostname;
        if(hostname.includes('online.ifurn.site')){
            this._baseUrl = protocol + "//" +  hostname + '/data';
            this._baseIfurn = protocol + "//"  + hostname;
            this._glLink = protocol + "//" + this._urlDefGl;
            this._crmLink = protocol + "//" + this._urlDefCrm;
            this._mainLink = protocol + "//" + this._urlDefMain;
            this._openFileRequest = protocol + "//" + this._openFileDefApi;
        }
        else if(hostname.includes('ifp')){
            this._baseUrl = 'http://local-api.ifp.com/api';
            this._baseIfurn = 'http://local-api.ifp.com';
            this._hostName = 'http://local-newgo.ifp.com';
            this._glLink = 'http://local-gl.ifp.com';
            this._mainLink = 'http://go2.ifurn.pro';
            this._crmLink = 'http://local-crm.ifp.com';
            this._openFileRequest = "http://" + "local-d.ifp.com?";
        }
        else if (!hostname.includes(checkHosts[0]) && !hostname.includes(checkHosts[2]) && !hostname.includes('127.0.0.1') && !hostname.includes('ifurn.local')) {
            this._baseUrl = protocol + "//" + 'api.' + hostname + '/api';
            this._baseIfurn = protocol + "//" + 'api.' + hostname;
            this._glLink = protocol + "//" + this._urlDefGl;
            this._crmLink = protocol + "//" + this._urlDefCrm;
            this._mainLink = protocol + "//" + this._urlDefMain;
            this._openFileRequest = protocol + "//" + this._openFileDefApi;
        } else {
            if (delimiter) {
                if (delimiter.input.match(/(\d+)/g)) {
                    this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "api2.ifurn.pro/api";
                    this._baseIfurn = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "api2.ifurn.pro";
                    this._glLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "gl2.ifurn.pro";
                    this._crmLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "crm2.ifurn.pro";
                    this._mainLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "go2.ifurn.pro";
                    this._openFileRequest = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + "d2.ifurn.pro?";
                } else {
                    this._baseUrl = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefApi;
                    this._baseIfurn = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlIfurnApi;
                    this._glLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefGl;
                    this._crmLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefCrm;
                    this._mainLink = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._urlDefMain;
                    if (delimiter.input.includes('test')) {
                        this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
                    } else {
                        this._openFileRequest = protocol + "//" + hostname.substr(0, delimiter.index) + "-" + this._openFileDefApi;
                    }
                }

            } else {
                if (hostname.includes("localhost") || hostname.includes('127.0.0.1') || hostname.includes('ifurn.local') || hostname.includes('em.ifurn.pro')) {
                    this._baseUrl = 'https://api2.ifurn.pro/api';
                    this._baseIfurn = 'https://api2.ifurn.pro';
                    this._hostName = 'https://newgo2.ifurn.pro';
                    // this._hostName = 'http://localhost:3000';
                    this._glLink = 'https://gl2.ifurn.pro';
                    this._crmLink = 'https://crm2.ifurn.pro';
                    this._mainLink = 'https://go2.ifurn.pro';
                    this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
                } else if (hostname.match(/(\d+)/g)) {
                    this._baseUrl = protocol + '//api2.ifurn.pro/api';
                    this._baseIfurn = protocol + '//api2.ifurn.pro';
                    this._glLink = protocol + '//gl2.ifurn.pro';
                    this._crmLink = protocol + '//crm2.ifurn.pro';
                    this._mainLink = protocol + '//go2.ifurn.pro';
                    this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
                } else {
                    this._baseUrl = protocol + "//" + this._urlDefApi;
                    this._baseIfurn = protocol + "//" + this._urlIfurnApi;
                    this._glLink = protocol + "//" + this._urlDefGl;
                    this._crmLink = protocol + "//" + this._urlDefCrm;
                    this._mainLink = protocol + "//" + this._urlDefMain;
                    this._openFileRequest = protocol + "//" + "d2.ifurn.pro?";
                }


            }
        }

    }

    _errors = null;
    _apiKey = '123456789';
    _withCredentials = 'include';

    async _send(url, method, data, headers = {}, resType = 'json', isReturnError = false, optionsRequest = {}, isFormData = false) {
        let body = null;
        let options = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: this._withCredentials,
            headers: {
                'Content-Type': 'application/json',
                'User-Token': localStorage.getItem(LS_USER_TOKEN),
                'Client-Token': localStorage.getItem(LS_CLIENT_TOKEN),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',
                ...headers
            },
            ...optionsRequest,
            redirect: 'follow',
            referrerPolicy: 'unsafe-url',
        }
        if (method === 'POST' || method === 'PUT') {
            if (!isFormData) {
                options.body = JSON.stringify(data);
            } else if (isFormData) {
                options.body = data;
            }
        }


        const res = await fetch(`${this._baseUrl}${url}`, options);

        if (res.status === 403) {
            // clearAllStorage();
            localStorage.removeItem('ifurn_service_main_user_token');
            localStorage.removeItem('ifurn_service_main_user_firm');
            window.location.href = '/login';
        }
        let urlRedirect = '';
        for (let entry of res.headers.entries()) {
            if (entry[0] === 'correct-server') {
                urlRedirect = entry[1];
            }
        }
        for (let entry of res.headers.entries()) {
            if (entry[0] === 'auth-token') {
                urlRedirect += '?auth_token=' + entry[1];
            }
        }

        if (!!urlRedirect) {
            window.location.href = urlRedirect;
            return
        }

        try {
            if (resType === 'json') {
                body = await res.json();
            }
            if (resType === 'text') {
                body = await res.text();
            }
            if (resType === 'blob') {
                body = await res.blob();
            }
            if (resType === 'buffer') {
                body = await res.arrayBuffer();
            }

        } catch (e) {
            body = false;
        }
        watcherApiPauseSite(body);

        let find_type_api_error = getErrorByResponse(body);
        if (!!find_type_api_error?.length) {
            this._errors = {api_error: find_type_api_error}
            return false;
        }

        if (body.error_api && !isReturnError) {
            this._errors = body.error_api;
            return false;
        } else if (body.error_api && isReturnError) {
            this._errors = body.error_api;
            return body.error_api
        }

        return body;
    }

    async _send_form_data({uri, method = "GET", data, headers = {}}) {
        const res = await fetch(`${this._baseUrl}${uri}`, {
            method: method,
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',
                ...headers
            },
            body: data
        });

        if (res.ok) {
            let response = await res.json();
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }
        return {error: `${res.statusText}`}
    }

    _getErrors() {
        try {
            if (!this._errors) {
                return false;
            }
            let errorText = '';
            let error_entry = this._errors?.api_error ?? null
            if (Array.isArray(error_entry) && !!error_entry?.length) {
                error_entry.forEach(e => {
                    if (typeof e.error === 'string') {
                        errorText += `${e.error},`;
                    } else {
                        for (const key in e.error) {
                            if (Object.prototype.hasOwnProperty.call(e.error, key)) {
                                errorText += `${e.error[key]},`;
                            }
                        }
                    }
                });
            }
            this._errors = null;
            return errorText;
        } catch (e) {
            console.log(e.message);
            this._errors = null;
            return false;
        }
    }

    async _sendFileData(url, files, data = null, firm = localStorage.getItem('ifurn_service_main_user_firm')) {

        const formData = new FormData();

        formData.append('firm', firm);

        for (let i = 0; i < files.length; i++) {
            formData.append('import_porject_' + i, files[i])
        }

        if (data) {
            data.forEach(e => {
                formData.append(e.name, e.value);
            })
        }

        const res = await fetch(`${this._baseUrl}${url}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: formData
        });

        const body = await res.json();

        if (body.error_api) {
            this._errors = body.error_api;
            return false;
        }

        return body;

    }

}

export default MainApi;