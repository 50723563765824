export const isOpenCuttingPage = state => state.cuttingChart.isOpenPage;

export const getCuttingCardDT = state => state.order.order.cutting_card;

export const getMaterialsDT = state => state.order.order.material;

export const getCuttingEdgingTotalDT = state => state.order.order.cutting_card.edging_total;

export const getPartsDT = state => state?.order?.order?.part || [];

export const getCuttingLblDT = state => state.order.order.cutting_card?.lbl ?? [];

export const isCuttingPreviewModal = state => state.cuttingChart.cuttingPreviewModal;
export const isCuttingPrintModal = state => state.cuttingChart.cuttingPrintModal;

export const getCuttingDataForHtml = state => state.cuttingChart.cuttingDataForHtml;

export const getMaterialWithStockType = (goods_id) => (state) => {
    let calculateMaterial = state.order.order.calculate?.material ?? null;
    let material = state.order.order.material?.find(e => Number(e.goods_id) === Number(goods_id)) ?? null;
    let calcMaterial = null;
    if (Array.isArray(calculateMaterial)) {
        calcMaterial = calculateMaterial?.find(e => Number(e.id) === Number(goods_id));
    }

    let calcType = calcMaterial?.calc_type;
    let resToClient = Number(material?.res_to_client);

    return {
        material: material,
        calcMaterial: calcMaterial,
        resToClient: resToClient,
        calcType: calcType,

    }


}