import { tools } from './tools.js';
import { measurementsLib } from './measurements.js';
import { translateLib } from './translate.js';

export const bores = {
  verticalBore(x, y, r, z, side, partZ, boreId, onSide, strokeWidth = '1px') {
    let colors = {
      f: [tools.boreBlind, tools.boreThrough],
      bb: [tools.backBoreBlind, tools.backBoreThrough],
      l: 'white',
      r: 'white',
      b: 'white',
      t: 'white',
    };

    let fill;

    if (onSide) {
      fill = colors[side];
    } else {
      let [colorBlind, colorThrough] = colors[side];
      fill = z < partZ ? colorBlind : colorThrough;
    }

    return tools.circle(
      x, y, r, {
      fill: fill,
      hover_show: boreId,
      'stroke-width': strokeWidth
    }
    );
  },
  boreInfoHighlight(
    x, y, x1, y1, x0, y0, x01, y01, z, r, boreId,
    parentTranslate, partX, partY, partY1, translateFunc,
    side, operationId, startPoint, verticalMirror, params = {}
  ) {
    let defaultParams = {
      onSide: false
    };
    params = { ...defaultParams, ...params };

    let T = translateFunc;
    let fontSize = 15;
    let translate = [parentTranslate[0] + r, parentTranslate[1]];
    let preTranslations = {
      l: translateLib.BORE_SIDE_L,
      r: translateLib.BORE_SIDE_R,
      b: translateLib.BORE_SIDE_B,
      t: translateLib.BORE_SIDE_T,
      f: translateLib.BORE_SIDE_F,
      bb: translateLib.BORE_SIDE_BB,
    };
    side = preTranslations[side];

    let startPointTspan = tools.getStartPointTspan(startPoint, T);
    let imageDiffersTspans = '';

    if (verticalMirror) {
      imageDiffersTspans = tools.getVerticalMirrorTspan(T, { x: 0, dy: '1.3em' });
    }

    let xText = tools.rnd(x0);

    if (tools.rnd(x0) !== tools.rnd(x01)) {
      xText = `${tools.rnd(x0)} / ${tools.tspan(tools.rnd(x01), { fill: tools.xCoordColor })}`;
    }

    let yText = tools.rnd(y0);

    if (tools.rnd(y0) !== tools.rnd(y01)) {
      yText = `${tools.rnd(y0)} / ${tools.tspan(tools.rnd(y01), { fill: tools.yCoordColor })}`;
    }

    if (verticalMirror) {
      let redTspan = (t) => tools.tspan(t, { fill: 'red' });

      if (tools.rnd(y0) !== tools.rnd(y01)) {
        yText = (
          `${yText} / `
          + `[[${redTspan(partY - y0)}/${redTspan(partY1 - y01)}]]`
        );
      } else {
        yText = `${yText} / [[${redTspan(partY - y0)}]]`;
      }
    }
    return tools.g([
      tools.g([
        tools.operationIdText(operationId, x, y),
        tools.line(
          x - r, -20, x - r, !params.onSide ? partY + 20 : 20, {
          'stroke-dasharray': '8 4',
          'class': 'dash-line',
        }
        ),
        tools.line(
          !params.onSide ? 0 : x - 40, y,
          !params.onSide ? partX + 20 : x + 40, y, {
          'stroke-dasharray': '8 4',
          'class': 'dash-line'
        }
        )
      ], {
        translate: translate
      }),
      tools.operationTable([
        tools.text(
          (
            operationId !== null
              ? tools.operationIdTspan(`ID: ${operationId}`)
              : ''
          )
          + tools.tspan(
            T(translateLib.BORE_POPUP_NAME), {
            x: 0,
            dy: operationId ? '1.3em' : ''
          })
          + tools.tspan(
            `${T(translateLib.BORE_SIDE)}: ${T(side)}`, {
            x: 0, dy: '1.2em'
          })
          + tools.tspan(
            `${T(translateLib.BORE_D)}: ${tools.rnd(r * 2)}`, {
            x: 0,
            dy: '1.2em'
          })
          + tools.tspan(
            `${T(translateLib.BORE_Z)}: ${tools.rnd(z)}`, {
            x: 0, dy: '1.2em'
          })
          + tools.tspan(
            `${T(translateLib.BORE_X)}: ${xText}`, {
            x: 0, dy: '1.2em'
          })
          + tools.tspan(
            `${T(translateLib.BORE_Y)}: ${yText}`, {
            x: 0, dy: '1.2em'
          })
          + startPointTspan,
          { 'font-size': `${fontSize}px` }
        )
      ], true, { table_id: boreId })
    ], { id: boreId });
  },
  horizontalBore(x, y, z, r, boreId, side, partX, partY) {
    let w, h, fill;

    if (side === 'l') {
      [x, y, w, h, fill] = [0, y - r, z, r * 2, tools.boreBlind];
    } else if (side === 'r') {
      [x, y, w, h, fill] = [partX - z, y - r, z, r * 2, tools.boreBlind];
    } else if (side === 'b') {
      [x, y, w, h, fill] = [x - r, partY - z, r * 2, z, tools.boreBlind];
    } else if (side === 't') {
      [x, y, w, h, fill] = [x - r, 0, r * 2, z, tools.boreBlind];
    }

    return tools.rect(
      x, y, w, h, { fill: fill, hover_show: boreId }
    );
  },
  boreTraces(x, y, z, r, partZ, partSide, boreSide, id) {
    let d = '';
    // let params = {
    //   stroke: 'black',
    //   'stroke-width': '3px',
    //   'stroke-dasharray': '8 4',
    //   fill: 'none',
    //   'measure-id': id,
    //   'class': 'measurements',
    // };
    let params = {
      stroke: 'none',
      fill: 'gray',
      'measure-id': id,
      'class': 'measurements',
    };

    if (boreSide === 'bb') {
      let swappedSides = {
        'r': 'l',
        'l': 'r',
        'b': 't',
        't': 'b',
      };
      partSide = swappedSides[partSide];
    }

    let paramsFat = {
      stroke: 'transparent',
      'stroke-width': '15px',
      fill: 'none',
      'measure-id': id,
      'class': 'measurements'
    };

    if (partSide === 'l') {
      d = [
        `m ${partZ} ${y - r}`,
        `l ${-z} 0`,
        `l 0 ${2 * r}`,
        `l ${z} 0`
      ].join(' ');
    } else if (partSide === 'b') {
      d = [
        `m ${x - r} 0`,
        `l 0 ${z}`,
        `l ${2 * r} 0`,
        `l 0 ${-z}`,
      ].join(' ');
    } else if (partSide === 'r') {
      d = [
        `m 0 ${y - r}`,
        `l ${z} 0`,
        `l 0 ${2 * r}`,
        `l ${-z} 0`,
      ].join(' ');
    } else if (partSide === 't') {
      d = [
        `m ${x - r} ${partZ}`,
        `l 0 ${-z}`,
        `l ${2 * r} 0`,
        `l 0 ${z},`
      ].join(' ');
    }

    let a = tools.path(d, params);
    let fat = tools.path(d, paramsFat);
    a += fat;
    return a;
  },
  bores(
    bores, partX, partY, partX1, partY1, partZ, parentTranslate,
    sideTranslates, measurementOffset, partSizesType, params = {}
  ) {
    let defaultParams = {
      language: 'en',
      onSide: false,
      partSide: null,
      typeSvg: null,
    };
    params = { ...defaultParams, ...params };

    let boreStrokeWidth;

    if (params.typeSvg === 'lbl') {
      boreStrokeWidth = '10px';
    } else {
      boreStrokeWidth = '1px';
    }

    let hovers = '';
    let verticalBores = '';
    let horizontalBores = '';
    let measurementCoords = [];
    let translateFunc = translateLib.translate(params.language);

    for (let bore of bores) {
      let verticalMirror = bore.virtual_vertical_mirror;
      let operationId = bore.id;
      let startPoint = bore.start_point;

      // let x0 = parseFloat(bore.x);
      // let x = x0;
      // let y0 = parseFloat(bore.y);
      // let y = y0;
      // let x01 = parseFloat(bore.x1);
      // let x1 = x01;
      // let y01 = parseFloat(bore.y1);
      // let y1 = y01;

      let { x: x0, y: y0 } = tools.getXY(bore, partSizesType)
      let x = x0, y = y0, x01 = x0, y01 = y0, x1 = x01, y1 = y01;

      [x, y, x1, y1] = tools.toFloat(x, y, x1, y1);
      let z = parseFloat(bore.z);
      let r = parseFloat(bore.d) / 2;

      if (verticalMirror) {
        y0 = y = partY - y;
        y01 = y1 = partY1 - y1;
      }

      let side = bore.side;

      if ('tb'.includes(side)) {
        y = partZ - y;
        y1 = partZ - y1;
      } else {
        y = partY - y;
        y1 = partY1 - y1;
      }
      let boreMeasureId = `bore_${operationId}`;

      if ((['f', 'bb'].includes(side) && params.partSide === null)
        || (!['f', 'bb'].includes(side) && params.partSide !== null)) {
        verticalBores += this.verticalBore(
          x, y, r, z, side, partZ, boreMeasureId, params.onSide, boreStrokeWidth
        );
        hovers += this.boreInfoHighlight(
          x, y, x1, y1, x0, y0, x01, y01, z, r, boreMeasureId,
          parentTranslate, partX, partY, partY1, translateFunc,
          side, operationId, startPoint, verticalMirror,
          { onSide: params.onSide }
        );
        measurementCoords.push(
          measurementsLib.measurementCoords(
            x, y, x1, y1, partX, partY, partX1, partY1, partZ,
            side, parentTranslate, sideTranslates, measurementOffset,
            { measureId: boreMeasureId, onSide: params.onSide }
          )
        );
      } else if (['f', 'bb'].includes(side) && params.partSide !== null) {
        verticalBores += this.boreTraces(
          x, y, z, r, partZ, params.partSide, side, boreMeasureId
        );
      } else {
        horizontalBores += this.horizontalBore(
          x, y, z, r, boreMeasureId, side, partX, partY
        )
      }
    }

    return [verticalBores, hovers, horizontalBores, measurementCoords];
  }
}
