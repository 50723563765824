import ApiService from "../../api/api";
import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {setProductionConstantsDataAC} from "./OrderReducer";
import {onOpenInstructionModal} from "./ModalReducer";

const returnDtSides = (data, key, vDef = null) => {
    const side = data.hasOwnProperty('side') && data.side.filter(s => Object.keys(s)[0] == key);
    return side.length >= 1 ? side[0][key] : vDef
}
export const setEditBoreForms = (data) => {
    return {
        bore: {
            side: [{bore_f: data.bore_f},
                {bore_l: data.bore_l},
                {bore_r: data.bore_r},
                {bore_t: data.bore_t},
                {bore_b: data.bore_b},
                {bore_bb: data.bore_bb},
            ],
            typeSide: {
                bore_in: Number(data.bore_in),
                bore_through: Number(data.bore_through)},
            d: data.d,
            z_max: data.z_max,
            on: data.on,
            equipment: {
                bore: 1,
                mill: 0,
                paz: 0,
            },
            isEdit: {
                edit: true,
                id: data.user_xnc_tools_id

            }
        },
    }
}
export const setEditPazForms = (data)=>{
    return{
        paz: {
            cut_back: data.cut_back,
            cut: data.cut,
            z_max: data.z_max,
            w: data.w,
            on: data.on,
            equipment: {
                bore: 0,
                mill: 0,
                paz: 1,
            },
            isEdit: {
                edit: true,
                id: data.user_xnc_tools_id
            }

        }
    }
}
export const setEditMillForms = (data)=>{
    return{
        mill: {
            mill_back: data.mill_back,
            type: {
                mill_left:  data.mill_left,
                mill_right: data.mill_right
            },
            z_max: data.z_max,
            on: data.on,
            d: data.d,
            name: data.name ?? 1,
            equipment: {
                bore: 0,
                mill: 1,
                paz: 0,
            },
            isEdit: {
                edit: true,
                id: data.user_xnc_tools_id
            }
        }
    }
}
const dataGeneration = (data, type) => {
    let sides = {
        bore_f: returnDtSides(data, 'bore_f', 0),
        bore_l: returnDtSides(data, 'bore_l', 0),
        bore_r: returnDtSides(data, 'bore_r', 0),
        bore_t: returnDtSides(data, 'bore_t', 0),
        bore_b: returnDtSides(data, 'bore_b', 0),
        bore_bb: returnDtSides(data, 'bore_bb', 0),

    }
    return {
        user_xnc_tools_id: data.user_xnc_tools_id,
        user: data.user,
        on: data.on,
        z_max: data.z_max ?? null,
        d: data.d ?? null,
        ...sides,
        bore_in: data?.typeSide?.bore_in ?? null,
        bore_through: data?.typeSide?.bore_through ?? 0,
        mill_left: data?.type?.mill_left ?? null,
        mill_back: data.mill_back ?? null,
        mill_right: data?.type?.mill_right ?? null,
        cut: data.cut ?? 0,
        cut_back: data.cut_back ?? null,
        w: data.w ?? null,
        name: data.name ?? "0",
        equipment: data.equipment ?? {}


    }
}

const actionTypes = {
    initial: "SETTINGS/INITIAL_VALUE",
    changeProduction: "SETTINGS/CHANGE_PRODUCTION",
    instrumentsBoreForm: 'INSTRUMENTS/BORE_FORM',
    instrumentsPazForm: 'INSTRUMENTS/PAZ_FORM',
    instrumentsMillForm: 'INSTRUMENTS/MILL_FORM',
    resetFormValue: 'INSTRUMENTS/RESET_FORM_DT',
    getUserId: 'GLOBAL/GET_USER_ID',
    setTools: 'INSTRUCTION/SET_TOOLS',
    setEditDTForm :'INSTRUCTION/SET_EDIT_DT_FORM'
}
const api = new ApiService();

const initialState = {
    userId: null,
    base: [],
    folder: [],
    production: {},
    instrumentsForms: {
        bore: {
            side: [],
            typeSide: {bore_in: 0, bore_through: 0},
            d: null,
            z_max: null,
            on: 1,
            equipment: {
                bore: 1,
                mill: 0,
                paz: 0,
            },
            isEdit: {
                edit: false,
                id: null
            }
        },
        paz: {
            cut_back: null,
            cut: null,
            z_max: null,
            w: null,
            on: 1,
            equipment: {
                bore: 0,
                mill: 0,
                paz: 1,
            },
            isEdit: {
                edit: false,
                id: null
            }

        },
        mill: {
            mill_back: null,
            type: {
                mill_left: 1,
                mill_right: null
            },
            z_max: null,
            on: 1,
            d: null,
            name: 1,
            equipment: {
                bore: 0,
                mill: 1,
                paz: 0,
            },
            isEdit: {
                edit: false,
                id: null
            }
        }
    },
    tools: []

}

export const actions = {
    initialProductionValues: ({production, base, base_folder}) => {
        return {
            type: actionTypes.initial,
            payload: {
                production,
                base,
                folder: base_folder
            }
        }
    },
    changeProduction: (payload) => {
        return {
            type: actionTypes.changeProduction,
            payload
        }
    },
    onChangeInstrumentsBoreForm: ({key, dt}) => {
        return {
            type: actionTypes.instrumentsBoreForm,
            payload: {key, dt}
        }
    },
    onChangeInstrumentsPazForm: ({key, dt}) => {
        return {
            type: actionTypes.instrumentsPazForm,
            payload: {key, dt}
        }
    }, onChangeInstrumentsMillForm: ({key, dt}) => {
        return {
            type: actionTypes.instrumentsMillForm,
            payload: {key, dt}
        }
    },
    onResetFormValue: (form) => {
        return {
            type: actionTypes.resetFormValue,
            payload: {form}
        }
    },
    getUserIdApp: (id) => {
        return {
            type: actionTypes.getUserId,
            payload: {id}
        }
    },
    setTools: (payload) => {
        return {
            type: actionTypes.setTools,
            payload: payload
        }
    },
    setInitialValuesForm : ({form, initialValue})=>{
        return{
            type: actionTypes.setEditDTForm,
            payload:{form, initialValue}
        }
    }
}


const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.initial: {

            return {
                ...state,
                base: action.payload.base,
                folder: action.payload.folder,

            }
        }
        case actionTypes.changeProduction: {
            return {
                ...state,
                base: [...action.payload.base]

            }
        }
        case actionTypes.instrumentsBoreForm: {
            return {
                ...state,
                instrumentsForms: {
                    ...state.instrumentsForms,
                    bore: {
                        ...state.instrumentsForms.bore,
                        [action.payload.key.trim()]: action.payload.dt
                    }
                }
            }
        }
        case actionTypes.instrumentsPazForm: {
            return {
                ...state,
                instrumentsForms: {
                    ...state.instrumentsForms,
                    paz: {
                        ...state.instrumentsForms.paz,
                        [action.payload.key.trim()]: action.payload.dt
                    }
                }
            }
        } case actionTypes.instrumentsMillForm: {
            return {
                ...state,
                instrumentsForms: {
                    ...state.instrumentsForms,
                    mill: {
                        ...state.instrumentsForms.mill,
                        [action.payload.key.trim()]: action.payload.dt
                    }
                }
            }
        }
        case actionTypes.resetFormValue: {
            return {
                ...state,
                instrumentsForms: {
                    ...state.instrumentsForms,
                    [action.payload.form]: initialState.instrumentsForms[action.payload.form]
                }
            }
        }
        case actionTypes.getUserId: {
            return {
                ...state,
                userId: action.payload.id
            }
        }
        case actionTypes.setTools: {
            return {
                ...state,
                tools: [
                    ...action.payload
                ]
            }
        }
        case actionTypes.setEditDTForm:{
            return{
                ...state,
                instrumentsForms: {
                    ...state.instrumentsForms,
                    [action.payload.form] : {...action.payload.initialValue[action.payload.form]}
                }

            }
        }

        default: {
            return state
        }
    }
}
export const getGlobalGodeUser = () => async (dispatch, getState) => {
    const clientId = getState().order?.order?.client || getState().auth?.user?.id;
    if(clientId) {
        try {
            const userIdResponse = await api._getGodeUser(Number(clientId));

            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
            } else {
                dispatch(actions.getUserIdApp(userIdResponse.user))
            }
        } catch (e) {
            dispatch(changeLoaderStatusAC(false));
        }
    }else{
        dispatch(changeLoaderStatusAC(false));
        checkErrorsAPI('', 'api:/clients/get_user/{id}, M:G')
    }

}

export const initialSettingsFirm = () => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Получаем настройки компании и произвдственные' +
        ' ограничения...')));
    try {
        const response = await api._getSettingsFirmProduction();

        if (response.hasOwnProperty('error_api') && api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        } else if (!response.hasOwnProperty('error_api')) {
            dispatch(actions.initialProductionValues({...response}));
            dispatch(setProductionConstantsDataAC(response.production));
            // dispatch(setProductionConstantsDataAC(response.production));
            dispatch(getGlobalGodeUser());
        }
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        checkErrorsAPI('', 'api:/settings/firm,, M:G')

        console.log(e.message)
    }
    dispatch(changeLoaderStatusAC(false))
}

export const onChangeValueFieldSetting = (payload) => async (dispatch, getStore) => {

    try {
        const production = getStore().order.order.production_constants;
        const base = getStore().settingFirm.base;
        const response = await api._onSetParamsSettings(payload.data);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }
        if (response.status == 'good' && response.hasOwnProperty('db')) {
            const productionUpdate = {
                ...production,
                [`${payload.symbol}`]: response.db[`value_${payload.data.type}`]
            }
            const baseUpdate = base?.map(b => {
                if (b.symbol === payload.symbol) {
                    b = {
                        ...b,
                        [`value_${b.type}`]: response.db[`value_${b.type}`]
                    }
                }
                return b;
            })
            dispatch(actions.changeProduction({base: baseUpdate}));
            dispatch(setProductionConstantsDataAC(productionUpdate));
        }

    } catch (e) {
        checkErrorsAPI('', 'api:/settings/, M:P')
        dispatch(changeLoaderStatusAC(false));

        console.log(e.message)
    }
}

export const onSendTools = (data, id = null, mFType) => async (dispatch, getState) => {
    const userId = Number(getState().settingFirm.userId);
    const tools = getState().settingFirm.tools;
    let DT = dataGeneration({...data, user_xnc_tools_id: id, user: userId});
    let updateTools;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Обрабатываем данные по инструментам и станкам...')));

    try {
        const response = await api._onSendInstructionTools(DT);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        } else {
            if (response.result == 'ok') {
                if (id != null) {
                    updateTools = tools.map(t => {
                        if (Number(t.user_xnc_tools_id) == Number(response.res_id)) {
                            t = {
                                ...t,
                                ...DT
                            }
                        }
                        return t;
                    });
                } else {
                    DT={
                        ...DT,
                        user_xnc_tools_id: Number(response.res_id)
                    }
                    tools.push(DT)
                    updateTools = [...tools];
                }
                dispatch(actions.setTools(updateTools));
                dispatch(onOpenInstructionModal({key: mFType, dt: false}))
                dispatch(actions.onResetFormValue(mFType));
            }
            dispatch(changeLoaderStatusAC(false));


        }
    } catch (e) {
        checkErrorsAPI('', 'api:/tools/, M:P')

        dispatch(changeLoaderStatusAC(false));

        console.log(e);
    }
}
export const getToolsSettingsFirm = () => (dispatch,) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Получаем данные по инструментам и станкам...')));
    api.getTools()
        .then(res => {
            if (res) {
                dispatch(actions.setTools(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }
        }).catch(e=> {
        checkErrorsAPI('', 'api: `/tools/equipment/?firm={firm}`, M:G')

        dispatch(changeLoaderStatusAC(false));

    })
};

export const deleteToolsSettingsFirm = (id) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    const tools = getState().settingFirm.tools;
    try {
        const response = await api._removeToolsSettingFirm(id);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        } else {
            if(response.status == 'ok') {
                const update = tools.filter(t => Number(id) != Number(t.user_xnc_tools_id))
                dispatch(actions.setTools(update));
            }
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        console.log(e);
        checkErrorsAPI('', 'api:/tools/id, M:D')

        dispatch(changeLoaderStatusAC(false));
    }
}
export default SettingsReducer;