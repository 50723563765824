export const cutting_style = `
         <style type="text/css">
    
          @media print {
            body {-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}
        }
        table {
            border-collapse: collapse;
            border: 2px solid white;
        }
        .cutting-chat-view{
        page-break-inside: avoid;
        }
        .settings-section .main-parts-table thead th {
            text-align: center;
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
        
        .settings-section .head .field select {
            display: block;
        }
        
        .settings-section .filed label {
            font-size: 18px; /* display: block; */
            margin-right: 12px;
            color: #605d81;
            font-weight: bold;
        }
        
        .material-select {
            width: max-content;
            max-width: 100%;
            padding: 8px 12px;
        }
        
        .settings-section .main-parts-table td {
            padding: 10px;
            color: #605d81;
        }
        
        .cutting-chart-view__item {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
        }
        
        .cutting-chat-view {
            position: relative;
        }
        .line_red{
      background-image: linear-gradient(to right, white 5px, #fe5f5e 1px);
      background-size: 7px;
        }
          .line_green{
           background-image: linear-gradient(#b2ff81 1px, transparent 1px);
          background-size: 100% 7px;
        }
.line_grey{
  background-image: linear-gradient(#cccccc 1px, transparent 1px);
          background-size: 100% 7px;
}
.line_orange{
  background-image: linear-gradient(#ffa50045 1px, transparent 1px);
          background-size: 100% 7px;
}
        .cutting-chat-view img {
            width: 100%;
            height: 100%;
        }
        
        .cutting-chart-detail {
            border: 2px solid black;
              print-color-adjust: exact; 
        }
        
        .cutting-chart-detail-head .title {
            color: rgba(96, 93, 129, 1);
            font-size: 16px;
            font-weight: 500;
            margin-right: 10px;
        }
        
        .cutting-chart-detail-head {
            margin-bottom: 15px;
            font-size: 18px;
        }
        
        .detail-rest {
            width: 100%;
            height: 100%;
            print-color-adjust: exact; 
        }
        
        .stitching-cut {
            width: 100%;
            height: 100%;
            border: 1px solid white;
              print-color-adjust: exact; 
        }
        
span.t, span.b, span.l, span.r,
span.count,
span.h,
span.w {
    position: absolute;
    background: rgba(255, 255, 255, 0.87);
    font-size: 15px;
    font-weight: normal;
    line-height: 12px;
    letter-spacing: -0.5px;
}

span.t {
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
}

span.b {
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
}
span.l {
    top: 50%;
    left: 3px;
    transform: translateY(-50%);
}

span.r {
    top: 50%;
    right: 3px;
    transform: translateY(-50%);
}


span.count {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    background: white;
}

span.w {
    top: 3px;
    left: 3px;
}

span.h {
    right: 3px;
    bottom: 3px;
}
.h.turn,
.w.turn{
    word-break: break-all;
    width: 8px;
    text-align: center;
}

.w.turn{
    top:0px;
    left: 1px;

}
.h.turn{
    right: 0px;
    bottom: 1px;
}
span.t.turn {
    left: 35%;
    top: 0px;
}

span.b.turn {
    left: 35%;
    bottom: 0px;
}

span.l.turn {
    transform: none;
    left: 15px;
}

span.r.turn {
    right: 25px;
}
.cutting-chart-detail.min-h .r.pos,
.cutting-chart-detail.min-h .b{
    bottom: -2px;
    left: 55%;
}
.cutting-chart-detail.min-h .r,
.cutting-chart-detail.min-h .b.pos{
right: 30%;
}
.cutting-chart-detail.min-h .l,
.cutting-chart-detail.min-h .t.pos{
    left: 30%;
}
.cutting-chart-detail.min-h .l.pos,
.cutting-chart-detail.min-h .t{
    top: -2px;
    left: 45%;
    /*left: 30%;*/
}
.cutting-chart-detail.min-h .l.pos,
.cutting-chart-detail.min-h .r.pos,
.cutting-chart-detail.min-h .b,
.cutting-chart-detail.min-h .t,
.cutting-chart-detail.min-h .count{
    font-size: 14px;
}
.cutting-chart-detail.min-h.min-side .b.pos,
.cutting-chart-detail.min-h.min-side.l{
    left: 10px;
}
.cutting-chart-detail.min-h.min-side .t.pos,
.cutting-chart-detail.min-h.min-side .r {
      right:10px;
  }
  .cutting-main-view.zoom  .cutting-chart-detail span {
    font-size: 17px !important;
}

        .cutting-chart-head {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 25px;
            width: 100%;
        }
        
        .cutting-chart-head * {
            cursor: pointer;
        }
        
        .cutting-card-settings-print {
            width: 100%;
        }
        
        .cutting-main-view {
            /*padding-bottom: 55px;*/
            width: max-content;
            min-width: 800px;
            margin: 0 auto 55px auto;
            max-width: 100%;   
             /*page-break-inside: auto;*/
        
        }
        
        .cutting-chart-detail .preview {
            position: absolute;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .cutting-chart-detail .preview img {
            height: auto;
            max-height: 100%;
            width: 100%;
            max-width: 100%;
        }
        
        
        .rest-tr {
            background: #0080001c !important;
        }
        
        .clr-red {
            color: red !important;
        }
        
       .form-qr-container {
            display: flex;
            align-items: center;
            max-width: 1200px;
            margin: 0 auto 25px auto;
        }
       .form-qr-container  .qr-info{
       column-count: 2;
       }
        .form-qr-container img {
            margin-right: 10px;
        }
        
        .form-qr-container div {
            line-height: 18px;
            font-size: 14px;
        }
        
        .form-qr-container strong {
            color: #605d81;
        }
        .form-qr-container strong {
            color: #605d81;
        }
        
        .cutting-material-result{
            margin-top: 25px;
        }
          </style>

`