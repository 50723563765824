import getT from "./getT"
import ApiService from "../api/api";


export default function({order, subjectTitle}){
const api = new ApiService()
  return `
    <style>
        .panel-print{
        z-index: 9;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
            text-align: right;
            margin: 20px 0px;
        }
        .panel-print button{
            background: none;
            border: none;
            outline: none;
        }
        .panel-print form{
        margin-bottom: 0px;
        }
        .mail-void {
    position: relative;
}
.mail-void:hover form{
    display: flex;
}

.mail-void form {
    position: absolute;
    background: white;
    box-shadow: 0 0 12px #f2f2f2;
    right: 0;
    padding: 10px;
    min-width: 420px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    border: 1px solid #f2f2f2;
}

.mail-void form div {
    display: grid;
    grid-template-columns: 100px 1fr;
    width: 100%;
    grid-gap: 15px;
    margin-bottom: 8px;
    align-items: center;
}

.mail-void form div label {
    font-size: 14px;
    color: #222;
    font-weight: 500;
    margin-bottom: 0px;;
}

.mail-void form button {
    background: #3586FF;
    width: 100%;
    padding: 8px 5px;
    color: white;
}

.mail-void form input {
    padding: 6px;
}
       @media print {
    /* здесь будут стили для печати */
     .panel-print {
       display: none !important;
    }
}
    </style>
<script>   
  // document.addEventListener("DOMContentLoaded", function(event){
    function onPrint() {   
       window.print()
    }   
    function onSendMail(event){
      event.preventDefault();
      let url = document.getElementById('uri')?.value + '/mail'
       fetch(url, {
         method: 'POST',
          mode: 'cors',
            cache: 'no-cache',
            credentials: this._withCredentials,
            headers: {
                'Content-Type': 'application/json',
                // 'Language': localStorage.getItem('ifurn_store_main_language') ? localStorage.getItem('ifurn_store_main_language') : 'ru',
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                // 'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',
            },
            redirect: 'follow',
            referrerPolicy: 'unsafe-url',
         body: JSON.stringify({
         action: 'form_send',
         subject: document.getElementById('subject')?.value,
         username: document.getElementById('user')?.value,
         email: document.getElementById('mail')?.value,
         text: btoa(unescape(encodeURIComponent(document.documentElement.innerHTML)))
         })
       }).then(async res =>{
           let body = await res.json();
           if(body?.response?.response){
             alert(body?.response?.response)
           }
           if(body?.error_api){
                alert(body?.error_api?.api_error?.map(error=> error)?.join(''))
           }
         })
return false;
    }
    // });
</script>   
<div class="container">
<div class=" panel-print">
<div class="mail">
    
    <div class="mail-void">
    <button><svg  width='24'
          height='24' 
          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  fill="#000000" version="1.1" id="Capa_1" viewBox="0 0 75.294 75.294" xml:space="preserve">
    <g>
    <path d="M66.097,12.089h-56.9C4.126,12.089,0,16.215,0,21.286v32.722c0,5.071,4.126,9.197,9.197,9.197h56.9   c5.071,0,9.197-4.126,9.197-9.197V21.287C75.295,16.215,71.169,12.089,66.097,12.089z M61.603,18.089L37.647,33.523L13.691,18.089   H61.603z M66.097,57.206h-56.9C7.434,57.206,6,55.771,6,54.009V21.457l29.796,19.16c0.04,0.025,0.083,0.042,0.124,0.065   c0.043,0.024,0.087,0.047,0.131,0.069c0.231,0.119,0.469,0.215,0.712,0.278c0.025,0.007,0.05,0.01,0.075,0.016   c0.267,0.063,0.537,0.102,0.807,0.102c0.001,0,0.002,0,0.002,0c0.002,0,0.003,0,0.004,0c0.27,0,0.54-0.038,0.807-0.102   c0.025-0.006,0.05-0.009,0.075-0.016c0.243-0.063,0.48-0.159,0.712-0.278c0.044-0.022,0.088-0.045,0.131-0.069   c0.041-0.023,0.084-0.04,0.124-0.065l29.796-19.16v32.551C69.295,55.771,67.86,57.206,66.097,57.206z"/>
    </g>
    </svg></button>
    <form action="" onsubmit="onSendMail(event)">
    <div>
       <label>${getT('Отправитель')}</label>
  
      <input type="text" value="${order?.user_name ?? ''}" onclick="" id="user">
</div> 
<div>
   <label>${getT('Получатель')}</label>
          <input type="email" value="${order?.client_email ?? ''}" onclick="" id="mail">
</div>
<div>
    <label>${getT('Тема')}</label>
    <input type="text" value="${subjectTitle}" onclick="" id="subject">
</div>
      <input type="hidden" value="${Number(order?.firm )===1 ? ' ' : order?.firm_name[localStorage.getItem('lang')]}" onclick="" id="firm">
      <input type="hidden" value="${api._baseUrl}" onclick="" id="uri" >
      <button type="submit">${getT('Отправить')}</button>
      </form>
   </div>
</div>
 
    
    
    <button onclick="window.print()" > 
     <svg
          xmlns="http://www.w3.org/2000/svg"
          width='24'
          height='24'
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width='2'
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 9V2h12v7M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2" />
          <path d="M6 14h12v8H6z" />
        </svg>
    </button>
</div> </div>`
}

