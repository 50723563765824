import React, {useState} from "react";
import {Link} from "react-router-dom";
import "./Cabinet.css";
import Edit from "../../Icons/TableActions/Edit";
import {compose} from "redux";
import Pagination from "../../components/Pagination/Pagination";
import ShowOrder from "../../Icons/ShowOrder";
import {useDispatch, useSelector} from "react-redux";
import {
    changeWarningModalValue,
    getOrderDetailThunk,
    onToggleCustomerBalanceModal
} from "../../store/reducers/ModalReducer";
import OrderDetail from "./OrderDetail";
import CabinetOrdersFilters from "./CabinetOrdersFilters";
import {withAuth} from "../../HOC/withAuth";
import Plus from "../../Icons/Plus";
import ChangeStatusProject from "./component/ChangeStatusProject";
import {getAuthUserDT, getCabinetUsserFirm, getOrdersCount} from "../../selectors/cabinet-sector";
import AttachDrawings from "../../Icons/Actions/AttachDrawings";
import ApiService from "../../api/api";
import {isAccessEdit} from "../SettingsFirm/help";
import CustomerStockList from "../SettingsFirm/CustomerBalances/CustomerStockList";
import {IconDelete} from "../../Icons/IconDelete";
import {deleteCabinetOrder, deleteCabinetOrderVersion} from "../../store/reducers/AuthReducer";
import {useLang} from "../../context/langProvider";
import CheckProjectModal from "../../components/Modals/CheckProjectModal/CheckProjectModal";
import {LS_IS_AUTH} from "../../constants";
import {getWarningModalSelected} from "../../selectors/modal-selector";
import CreateProjectModal from "../../components/Modals/createProjectModal/createProjectModal";
import {modalToggleCreateProjectAC} from "../../store/actions/modal-action";
import sketchUpImg from "../../images/sketch_up.png";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";
import IconQuestion from "../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../store/constats/help_article_constants";

const differentiationPossibilities = [2, 3, 4, 5, 6, 8];
const accessFirm = [1];
const apiService = new ApiService();

const Cabinet = (props) => {
    const {getT} = useLang();

    const ordersCount = useSelector(getOrdersCount)
    const userDT = useSelector(getAuthUserDT);
    const currentFirm = useSelector(getCabinetUsserFirm);
    const warning_modal_state = useSelector(getWarningModalSelected)
    const dispatch = useDispatch();
    const onHandlerRemoveOrder = () => {
        dispatch(deleteCabinetOrder({...warning_modal_state?.additional_data}))
    }
    const handlerToggleModalCreateProject = ()=>{
        let user_id = props.user.id;
        let firm = props.user.firm;
        dispatch(modalToggleCreateProjectAC({isOpen: true, user_id:user_id , firm: firm}))
    }
    const renderOrders = () => {
        return props.orders && props.orders.data && props.orders.data.map(e => {
            return <OrderRow order={e} getT={getT} currentFirm={currentFirm}
                             userId={props.userId}

            />;
        })
    };
    const handlerHelpArticle = (id)=>(e)=>{
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }

    return (

        <>
            <div className="col main">
                <div className="main-header ">
                    <h3 className="header-title">{getT('Мои Заказы')}</h3>
                    <div className="d-flex justify-end gap-10 flex-wrap ">
                        {JSON.parse(localStorage.getItem(LS_IS_AUTH)) && <a href={`${apiService._baseIfurn}/dxf`} className=" d-flex items-center btn-sketch">
                            <img src={sketchUpImg} alt=""  height={50}/>
                            <IconQuestion style={{top: '0px'}}
                                          onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.import_from_sketch_up)}
                            />
                        </a>}
                        {isAccessEdit(Number(currentFirm)) && JSON.parse(localStorage.getItem(LS_IS_AUTH)) ? <>
                            {(JSON.parse(localStorage.getItem(LS_IS_AUTH))) && userDT.hasOwnProperty('payment') &&
                                Array.isArray(userDT.payment) && userDT.payment.length == 0 && Number(ordersCount) < 1 && <>
                        <span onClick={handlerToggleModalCreateProject}>
                            <Plus cName="create-project-plus"/>
                        </span>
                                    <button className="create-project"
                                            onClick={handlerToggleModalCreateProject}>{getT('Создать проект')}</button>
                                </>
                            }
                            {(JSON.parse(localStorage.getItem(LS_IS_AUTH))) && userDT.hasOwnProperty('payment') && !Array.isArray(userDT.payment) &&
                                <>
                        <span onClick={handlerToggleModalCreateProject}><Plus
                            cName="create-project-plus"/></span>
                                    <button className="create-project"
                                            onClick={handlerToggleModalCreateProject}>{getT('Создать проект')}</button>

                                </>
                            }
                        </> : JSON.parse(localStorage.getItem(LS_IS_AUTH)) &&
                            <>                        <span onClick={handlerToggleModalCreateProject}><Plus
                                cName="create-project-plus"/></span>
                                <button className="create-project"
                                        onClick={handlerToggleModalCreateProject}>{getT('Создать проект')}</button>

                            </>
                        }
                    </div>



                </div>
                <CabinetOrdersFilters/>
                <div className="orders-list">
                    <table className={'table_account_orders'}>
                        <thead>
                        <tr>
                            <th>№</th>
                            <th>{getT('Название')}</th>
                            {/*<th>{getT('Код')}</th>*/}
                            <th className={'cabinet-action__td-mob'}>{getT('Действие')}</th>
                            <th>{getT('Статус')}</th>
                            <th>{getT('Дата')}</th>
                            {/*className={"cabinet-action__td "}*/}
                            <th className={'cabinet-action__td'}>{getT('Действие')}</th>
                            {/*{accessFirm.includes(Number(currentFirm)) && <th>{getT('Готовность услуг')}</th>}*/}
                        </tr>
                        </thead>
                        <tbody>
                        {renderOrders()}
                        </tbody>
                    </table>
                </div>
                <Pagination count={props.orders && props.orders.count} changeHandler={props.getOrders}/>
            </div>
            <OrderDetail/>
            <CheckProjectModal key={'CheckProjectModal'}/>
            <CustomerStockList getT={getT}/>
            <CreateProjectModal getT={getT}/>

        </>
);
};

const OrderRow = (props) => {

    const dispatch = useDispatch();

    const [isOpen, setOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    let versions = props.order.version?.map((item) => Number(item?.version));
    let actualVersion = props.order.version && versions ? Math.max(...versions) : null;
    const onOpenCustomerBalanceModal = (stock) => {
        dispatch(onToggleCustomerBalanceModal({key: 'listView', dt: {isOpen: true, stock: stock}}))
    }
    const onDeleteCurrentVersion = ({type = 'v', code, v}) => {
        dispatch(deleteCabinetOrderVersion({id: props.order.id, type: type, code, v}))
    }

    const handlerOpenModalDeleteOrder = () => {
        let payload = {
            isOpen: true, title: props.getT("Удалить проект"),
            additional_data:  {id: props.order.id, type: 'o', code: props.order.code}, onSuccessful: ()=>  dispatch(deleteCabinetOrder({id: props.order.id, type: 'o', code: props.order.code}))
        }
        // dispatch(changeWarningModalValue('additional_data', {id: props.order.id, type: 'o', code: props.order.code}));
        dispatch(changeWarningModalValue(payload));

    }
    return <>
        <tr>
            <td>{props.order.id} {props.order.code1c ? ` [${props.order.code1c }] `: null}</td>
            <td>{props.order.name}</td>
            {/*<td>{props.order.code}</td>*/}
            <td className={'cabinet-action__td-mob'}>
                {props.order.version && actualVersion && props.order.version.length > 0 && props.order.status && Number(props.order.status.id) === 1 &&
                    // {props.order.version && props.order.version.length > 0 && props.order.status && Number(props.order.status.id) === 1 &&
                    <div className="account_order_version_container">
                        <Edit onClick={() => setOpen(!isOpen)}/>
                        {isOpen &&
                            <div className="account_order_versions">
                                {props.order.version?.filter(({version}) => Number(version) === Number(actualVersion))?.map(e => {
                                    return <div className="account_version_item">
                                        <Link to={'/project/' + props.order.code + '/' + e.version}>
                                            {/*<b>{e.version}</b> от */}
                                            {e.date}
                                        </Link>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                }
                {props.order.status && props.order.status.id && Number(props.order.status.id) !== 1 && Number(props.order.status.id) !== 8 && <>
                    <div style={{cursor: 'pointer'}}
                         title={props.getT('Дополнительно')} onClick={() => dispatch(getOrderDetailThunk(props.order))}>
                        <div className="info-icon" style={{margin: '5px auto'}}></div>
                    </div>
                </>}

                {accessFirm.includes(Number(props.currentFirm)) && (Number(props.order.status.id) === 2) ? <span>
                <a href={`${apiService._glLink}?code=${props.order.code}&cs=1`} target={'_blank'}
                   title={props.getT('Изменить' +
                       ' раскрой')}>
                <AttachDrawings/>
                </a>
            </span> : ''}
            </td>
            <td className={"cabinet-action__mobile change-status-main"} style={{textAlign: 'center'}}>
                <div className="" style={{position: 'relative'}}>
                    <span> {props.order.status.name}</span>
                    {props.order.hasOwnProperty('status') && differentiationPossibilities.includes(Number(props.order.status.id)) && accessFirm.includes(Number(props.currentFirm)) &&
                        <ChangeStatusProject getT={props.getT} userId={props.userId} orderId={props.order.id}
                                             currentStatusId={Number(props.order.status.id)}/>}
                </div>
            </td>
            <td>{props.order.date}  </td>
            <td className={'cabinet-action__td'}>
                {props.order.version && props.order.version.length > 0 && props.order.status && [1, 2, 3, 4, 5, 6].includes(Number(props.order.status.id)) &&
                    <div className="account_order_version_container">
                        <Edit onClick={() => setOpen(!isOpen)}/>
                        {isOpen &&
                            <div className="account_order_versions">
                                {props.order.version?.filter(({version}) => Number(version) === Number(actualVersion))?.map(e => {
                                    return <div className="account_version_item">
                                        <a href={'/project/' + props.order.code + '/' + e.version} className="">  {e.date}</a>
                                    </div>
                                })}
                            </div>
                        }
                    </div>
                }

                {props.order.status && props.order.status.id && (Number(props.order.status.id) === 1 || Number(props.order.status.id) === 8) && <>
                    <span title={props.getT('Удалить заказ')} onClick={handlerOpenModalDeleteOrder}>
                        <IconDelete/>
                    </span>
                </>}
                {props.order.status && props.order.status.id && Number(props.order.status.id) !== 1 && Number(props.order.status.id) !== 8 && <>
                    <div style={{cursor: 'pointer'}} title={props.getT('Дополнительно')}
                         onClick={() => dispatch(getOrderDetailThunk(props.order))}>
                        <div className="info-icon" style={{margin: '5px auto'}}></div>

                    </div>
                </>}
                {/*{ <ShowOrder/>}*/}
                {isAccessEdit(Number(props.currentFirm)) && (Number(props.order.status.id) === 2) ? <span>
                <a href={`${apiService._glLink}?code=${props.order.code}&cs=1`} target={'_blank'}
                   title={props.getT('Изменить' +
                       ' раскрой')}>
                <AttachDrawings/>
                </a>
            </span> : ''}

                {isAccessEdit(Number(props.currentFirm)) && props.order.hasOwnProperty('goods_stock') && Array.isArray(props.order.goods_stock) && props.order.goods_stock.length >= 1 &&
                    <p className={'stock-order_icon'} title={props.getT('Используются остатки')}
                       onClick={() => onOpenCustomerBalanceModal(props.order.goods_stock)}
                    ><ShowOrder/></p>}
            </td>
            {/*{accessFirm.includes(Number(props.currentFirm)) && props.order.status && props.order.status.id && Number(props.order.status.id) == 4 ?*/}
            {/*<td><ListServices orderId={props.order.id} getT={getT}/></td>*/}
            {/*: <td></td>*/}
            {/*}*/}
        </tr>

    </>
};

export default compose(
    withAuth
)(Cabinet);

