import {
    saveOrderProjectDataThunk,
    setCalculateOrderThunk,
    setCuttingCardInProject,
    setCuttingPreviewAC,
    setOrderCopyProject,
    setOrderProjectDataAC,
    setOrderProjectDataThunk
} from "./OrderReducer";
import ApiService from "../../api/api";
import ApiGiblab from "../../api/apiGiblab";
import {changeCheckProjectModalValue, changeImportProjectModalValue} from "./ModalReducer";
import {getTranslateMessageAPI, manipulateSessionCalculate} from "../../helpers/helpers";
import {actionsCuttingChart} from "./CuttingChartReducer";
import {blankHtml, shortOrderFormWindowHtml} from "../../printHtml";
import apiImages from "../../api/apiImages";

import {ShortOrderHtml} from "../../printHtml/ShortOrderHtml";
import {dispatchCuttingCardOwnerUiDefThunk, dispatchedCuttingCardThunk} from "../thunks/cutting-thunk";
import {getCalculateShippingProcessingEdge} from "../../helpers/processing-edge-helpers";
import {calculateNumberOfProfilesHandlerAndEdge} from "../thunks/processing-edge-thunk";
import {dispatcherErrorThunk} from "../thunks/common-thunk";
import getT from "../../printHtml/getT";
import {utilLogger} from "../../helpers/util-logger";
import {CuttingAPI} from "../../api/CuttingAPI";
import {LS_LINK_T0_3D_CARD} from "../../constants";


const MATERIAL_EXPORT_BASIS = "MATERIAL_EXPORT_BASIS";
const ALLOW_REQUEST_CHECK_PROJECT = "ALLOW_REQUEST_CHECK_PROJECT";
const CHANGE_LOADER_STATUS = "CHANGE_LOADER_STATUS";
const CHANGE_LOADER_MESSAGE = "CHANGE_LOADER_MESSAGE";
const CHANGE_ALERT_STATUS = "CHANGE_ALERT_STATUS";
const CHANGE_MAIN_ORDER_DATA = "CHANGE_MAIN_ORDER_DATA";
const SET_PROJECTS_ERROR = "SET_PROJECTS_ERROR";
const SET_REGIONS_DATA_PROJECT = "SET_REGIONS_DATA_PROJECT";
const SET_FIRM_STORES_DATA = "SET_FIRM_STORES_DATA";
const SET_SERVICE_MAIN_ERRORS = "SET_SERVICE_MAIN_ERRORS";
const CHANGE_CABINET_FILTERS_ORDERS_VALUE_DATA = "CHANGE_CABINET_FILTERS_ORDERS_VALUE_DATA";
const RESET_CABINET_FILTERS_ORDERS_VALUE_DATA = "RESET_CABINET_FILTERS_ORDERS_VALUE_DATA";
const FILE_SET_CLARIFICATION_DT = "FILE/SET_CLARIFICATION_DT";
const SET_PROJECTS_CALC_ERROR = "SET_PROJECTS_CALC_ERROR";
const SET_RULE_SETTINGS_PROJECT = "SET_RULE_SETTINGS_PROJECT";
const NEWS_SET_LIST = "NEWS/SET_LIST"
const api = new ApiService();
const apiGiblab = new ApiGiblab();

const NEWS_INITIAL_VALUES = {
    page: 0, pages: 0, news: []
}

let initialState = {
    isAllowRequestCheckProject: true,
    loader: {
        active: true, message: null, count: 0
    },
    alert: {
        active: false, message: "", success: false
    },
    data: {
        version: null, code: null
    },
    currencies: [{name: "usd", id: 1}, {name: "uah", id: 2}, {name: "eur", id: 3}, {name: "nis", id: 4}, {
        name: "руб",
        id: 8
    }, {name: "pln", id: 5}, {name: "mdl", id: 6}, {name: "ron", id: 7}, {name: "HUF", id: 9}],
    units: [{id: "5", name: "м.пог."}, {id: "6", name: "м2"}, {id: "7", name: "шт."}, {id: "8", name: "кг."}, {
        id: "9",
        name: "к-т."
    }

    ],
    drops: [{id: "1", name: "Товары без потерь"}, {id: "2", name: "Отход 15%"}, {id: "3", name: "Отход 20%"}, {
        id: "4",
        name: "Поллиста горизонтально"
    }, {id: "6", name: "Поллиста вертикально"}, {id: "7", name: "Продажа листом"}, {id: "8", name: "Отходность 50%"}],
    regions: {
        at: {id: "at", label: "AT +43", code: "+43", mask: "111-111-1111"},
        bg: {id: "bg", label: "BG +359", code: "+359", mask: "(111) 111-111"},
        de: {id: "de", label: "DE +49", code: "+49", mask: "(111) 111-11-11"},
        et: {id: "et", label: "ET +372", code: "+372", mask: "1111-1111"},
        he: {id: "he", label: "HE +972", code: "+972", mask: "(111) 111-11-11"},
        hu: {id: "hu", label: "Hu +36", code: "+36", mask: "(111) 111-111"},
        lt: {id: "lt", label: "LT +370", code: "+370", mask: "(111) 11-1111"},
        lv: {id: "lv", label: "LV +371", code: "+371", mask: "1111-1111"},
        md: {id: "md", label: "MD +373", code: "+373", mask: "1111-1111"},
        pl: {id: "pl", label: "PL +48", code: "+48", mask: "(111) 111-111"},
        ro: {id: "ro", label: "RO +40", code: "+40", mask: "-11-111-1111"},
        ru: {id: "ru", label: "Ru +7", code: "+7", mask: "(111) 111-11-11"},
        sk: {id: "sk", label: "SK +421", code: "+421", mask: "(111) 111-111"},
        ua: {id: "ua", label: "UA +38", code: "+38", mask: "(111) 111-11-11"}

    },
    statuses: [{id: 1, name: "в редактировании"}, {id: 2, name: "подтверждён"}, {
        id: 3,
        name: "подтверждён в производство"
    }, {id: 4, name: "в работе"}, {id: 5, name: "выполнен"}, {id: 6, name: "готов к отгрузке"}, {
        id: 7,
        name: "отгружен"
    }, {id: 8, name: "отменен"}, {id: 9, name: "проблемный заказ"}],
    stores: [],
    errors_service: {
        parts: [], xnc: []
    },
    ordersFilters: {
        id: null, name: null, order_code: null, status: null, date_to: null, date_from: null
    },
    errors: null,
    errors_calc: null,
    files: {
        uploadClarification: {
            error: "", viyar: {}
        }
    },
    ruleSettingsProject: {
        logoUrl: "", infoText: false, phoneCode: "", visa: "", lang: "", viberQr: "", telegramQr: "", copyright: ""
    },
    materialExport: null,
    news: NEWS_INITIAL_VALUES
};

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case MATERIAL_EXPORT_BASIS: {
            return {
                ...state, materialExport: action.payload
            }
        }
        case ALLOW_REQUEST_CHECK_PROJECT: {
            return {
                ...state, isAllowRequestCheckProject: action.payload
            }
        }

        case SET_RULE_SETTINGS_PROJECT: {
            return {
                ...state, ruleSettingsProject: {...state.ruleSettingsProject, ...action.payload}
            };
        }
        case CHANGE_LOADER_STATUS:
            let count = action.status ? state.loader.count + 1 : state.loader.count - 1;
            if (count < 0) count = 0;
            let status = count > 0;
            let message = status ? state.loader.message : null;
            return {
                ...state, loader: {
                    ...state.loader, count: count, active: status, message: message
                }
            };
        case CHANGE_ALERT_STATUS:
            if (action.message == "" && action.status) {
                return {
                    ...state
                };
            }
            return {
                ...state, alert: {
                    active: action.status, message: action.message, success: action.success
                }
            };
        case CHANGE_LOADER_MESSAGE:
            return {
                ...state, loader: {
                    ...state.loader, message: action.message
                }
            };
        case SET_REGIONS_DATA_PROJECT:
            return {
                ...state, regions: action.regions
            };
        case SET_FIRM_STORES_DATA:
            return {
                ...state, stores: action.stores
            };
        case SET_SERVICE_MAIN_ERRORS:
            const errors_service = {...state.errors_service};
            errors_service[action.input] = action.value;
            return {
                ...state, errors_service: errors_service
            };
        case CHANGE_CABINET_FILTERS_ORDERS_VALUE_DATA:
            const ordersFilters = {...state.ordersFilters};
            ordersFilters[action.input] = action.value;
            return {
                ...state, ordersFilters: ordersFilters
            };
        case RESET_CABINET_FILTERS_ORDERS_VALUE_DATA:
            return {
                ...state, ordersFilters: initialState.ordersFilters
            };
        case SET_PROJECTS_ERROR:
            return {
                ...state, errors: action.errors
            };
        case CHANGE_MAIN_ORDER_DATA:
            const new_data = {...state.data};
            new_data[action.input] = action.value;
            return {
                ...state, data: {
                    ...new_data
                }
            };
        case SET_PROJECTS_CALC_ERROR : {
            return {
                ...state, errors_calc: action.errors
            };
        }
        case FILE_SET_CLARIFICATION_DT: {
            return {
                ...state, files: {
                    ...state.files, uploadClarification: {...action.payload}
                }
            };
        }
        case NEWS_SET_LIST: {
            return {
                ...state, news: {
                    ...state.news, ...action.payload
                }
            }
        }
        default:
            return state;
    }
};


/**
 * Action onToggleMaterialExportBasis -  set values .
 *
 * @param {boolean} payload - {file: '', help: base64} or null.
 * @returns {state} - update state.
 */
export const onToggleMaterialExportBasis = (payload) => {

    return {
        type: MATERIAL_EXPORT_BASIS, payload: payload
    }
}
/**
 * Action onToggleAllowRequestCheckProject -  toggle block request for send request "check project".
 *
 * @param {boolean} isToggle - off/on.
 * @returns {state} - update state.
 */
export const onToggleAllowRequestCheckProject = (isToggle) => {
    return {
        type: ALLOW_REQUEST_CHECK_PROJECT, payload: isToggle
    }
}
export const onSetRuleSettingsProject = (dt) => {
    return {
        type: SET_RULE_SETTINGS_PROJECT, payload: {
            ...dt
        }
    };
};
export const onSetFileSetClarificationDT = (payload) => {
    return {
        type: FILE_SET_CLARIFICATION_DT, payload
    };
};
export const changeLoaderStatusACS = (status) => {
    return {
        type: CHANGE_LOADER_STATUS, status: status
    };
};
export const changeLoaderStatusAC = (status) => (dispatch) => {
    if (status) {
        dispatch(changeLoaderStatusACS(status));
    } else {
        setTimeout(function () {
            dispatch(changeLoaderStatusACS(status));
        }, 750);
    }

};

export const changeLoaderMessageAC = (message) => {
    return {
        type: CHANGE_LOADER_MESSAGE, message: message
    };
};
export const setRegionsDataAC = (regions) => {
    return {
        type: SET_REGIONS_DATA_PROJECT, regions: regions
    };
};
export const changeAlertStatusAC = (status, message, success = false) => {
    return {
        type: CHANGE_ALERT_STATUS, status: status, message: message, success
    };
};
export const setFirmsOrdersStoresDataAC = (stores) => {
    return {
        type: SET_FIRM_STORES_DATA, stores: stores
    };
};
export const changeMainOrderDataAC = (input, value) => {
    return {
        type: CHANGE_MAIN_ORDER_DATA, input: input, value: value
    };
};
export const changeOrdersFiltersDataValueAC = (input, value) => {
    return {
        type: CHANGE_CABINET_FILTERS_ORDERS_VALUE_DATA, input: input, value: value
    };
};
export const resetOrdersFiltersDataValueAC = () => {
    return {
        type: RESET_CABINET_FILTERS_ORDERS_VALUE_DATA
    };
};
export const setServiceMainErrorsDataAC = (input, value) => {
    return {
        type: SET_SERVICE_MAIN_ERRORS, input: input, value: value
    };
};
export const setProjectErrorsAC = (errors) => {
    return {
        type: SET_PROJECTS_ERROR, errors: errors
    };
};
export const setProjectCalcErrorsAC = (errors) => {
    return {
        type: SET_PROJECTS_CALC_ERROR, errors: errors
    };
};
export const switchVersionProject = (version) => async (dispatch, getState) => {
    const order = getState().order.order;
    const versionCurrent = getState().commons.data.version;
    dispatch(changeLoaderStatusAC(true));
    api.saveProject(versionCurrent, order)
        .then(res => {
            if (res?.is_save) {
                const url = `${api._hostName}/project/${order.code}/${version}`;
                window.location = url;
            }
            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
            } else {
                dispatch(checkErrorsAPI(typeErrorApi.save, "api:  /projects/${version}, M:PUT", `Message = ${res?.res}, api_errors=${api._getErrors()}`))
                dispatch(changeLoaderStatusAC(false));
            }
        })
        .catch(e => {
            dispatch(checkErrorsAPI(typeErrorApi.save, "api:  /projects/${version}, M:PUT", `Message = ${e?.message}`))
        });
};

export const sendGiblabOrderProjectThunk = (order, version) => async (dispatch, getState) => {
    const state = getState();
    const {commons: {errors, errors_service: errorsService}} = state;
    try {
        dispatch(changeLoaderStatusAC(true));
        const res = await api.checkProject(order);

        if (res) {
            compareCheckProjectAndDispatch(errors?.parts, res.parts, setProjectErrorsAC, dispatch);
            const part_errors = Object.keys(res.parts).map(Number);
            compareCheckProjectAndDispatch(errorsService?.parts, part_errors, part_errors => setServiceMainErrorsDataAC("parts", part_errors), dispatch);
            compareCheckProjectAndDispatch(errorsService?.xnc, res.xnc, xnc => setServiceMainErrorsDataAC("xnc", xnc), dispatch);
            dispatch(changeCheckProjectModalValue("isOpen", true));
            dispatch(changeLoaderStatusAC(false));
            return false
        }
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Сохраняем текущий проект в iFurn...")));
        api.saveProject(version, order)
            .then(res => {
                if (res.is_save) {
                    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Форматируем проект iFurn для открытия ...")));
                    api.xmlTransform(order)
                        .then(response => {
                            if (api._errors || !response) {
                                let errors = api._getErrors() || 'error giblab'
                                dispatch(changeAlertStatusAC(true, errors));
                                dispatch(changeLoaderStatusAC(false));
                            }
                            document.getElementById("giblab_input_project").value = response.xml;
                            document.getElementById("session_id").value = response.session_id;
                            document.getElementById("session_data").value = JSON.stringify(response.session_data);
                            document.getElementById("giblab_send_form").submit();
                            //
                        }).catch(e => {
                        dispatch(typeErrorApi.save, "api: /project-transform/, method: post", `Message = ${e?.message}`);
                    });
                    return
                }
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                    return;
                }
                let error_msg = JSON.stringify({response: res?.res, api_error: api._getErrors()})
                throw new Error(error_msg)
            }).catch(e => {
            dispatch(checkErrorsAPI(typeErrorApi.save, "api: /projects/version/, method: put", `Message = ${e?.message}`));
        });
    } catch (e) {
        dispatch(checkErrorsAPI(typeErrorApi.save, "api: /projects/version/, method: put", `Message = ${e?.message}`));
        dispatch(changeLoaderStatusAC(false));
    }

};
// giblab

export const checkProjectBeforeSaveProject = (orderData = null, cb = null) => async (dispatch, getState) => {
    try {
        const state = getState();
        const order = orderData || state.order.order;
        const {commons: {errors, errors_service: errorsService}} = state;


        const res = await api.checkProject(order);

        if (res) {
            compareCheckProjectAndDispatch(errors?.parts, res.parts, setProjectErrorsAC, dispatch);
            const part_errors = Object.keys(res.parts).map(Number);
            compareCheckProjectAndDispatch(errorsService?.parts, part_errors, part_errors => setServiceMainErrorsDataAC("parts", part_errors), dispatch);
            compareCheckProjectAndDispatch(errorsService?.xnc, res.xnc, xnc => setServiceMainErrorsDataAC("xnc", xnc), dispatch);
            dispatch(changeCheckProjectModalValue("isOpen", true));

            return false
        } else {
            if (cb) cb()
        }


    } catch (e) {
        console.log(e.message)
    }
}

export const getGiblabDataThunk = (type, orderData = null, isSave = true) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    const state = getState();
    let order = orderData || state.order.order;
    const calculateIsOpen = getState().modal.shopingCard.isOpen;
    const {commons: {errors, errors_service: errorsService}} = state;

    try {
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем проекты на проверку...")));

        const res = await api.checkProject(order);

        if (res) {
            compareCheckProjectAndDispatch(errors?.parts, res.parts, setProjectErrorsAC, dispatch);
            //
            const part_errors = Object.keys(res.parts).map(Number);
            compareCheckProjectAndDispatch(errorsService?.parts, part_errors, part_errors => setServiceMainErrorsDataAC("parts", part_errors), dispatch);
            compareCheckProjectAndDispatch(errorsService?.xnc, res.xnc, xnc => setServiceMainErrorsDataAC("xnc", xnc), dispatch);
            dispatch(changeCheckProjectModalValue("isOpen", true));
            dispatch(changeLoaderStatusAC(false));
            return false
        }


        dispatch(onToggleAllowRequestCheckProject(false))
        if ((calculateIsOpen && type === "calc")) {
            if (isSave) {
                const isUpdate = await dispatch(saveOrderProjectDataThunk(false, false, false));
                if (!isUpdate) {
                    dispatch(changeLoaderStatusAC(false));
                    return false;
                }

                dispatch(setCalculateOrderThunk({
                    ...order,
                }, true));
                dispatch(changeLoaderStatusAC(false));
                return true;
            }
        }

        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Определяем источник данных...")));
        if (isSave) {
            const isUpdate = await dispatch(saveOrderProjectDataThunk(false, false, false));
            if (!isUpdate) {
                dispatch(changeLoaderStatusAC(false));
                return false
            }
        }

        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем данные для запроса...")));
        const {xml, red_res} = await apiGiblab._getXml(order);
        if (apiGiblab._errors) {
            dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
            dispatch(changeLoaderStatusAC(false));
            return
        }

        if (!xml || xml === "no_cs") {
            await handleXmlCondition(xml, order, dispatch);
            dispatch(changeLoaderStatusAC(false));
            return;
        }

        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Калькуляция раскроя...")));

        if (red_res) {
            await dispatch(gitlabMiscalculationCaching({
                type, result: red_res, order
            }));
            dispatch(changeLoaderStatusAC(false));
            return;
        }

        let calculateStorage = await manipulateSessionCalculate.get();

        if (calculateStorage) {
            await dispatch(gitlabMiscalculationCaching({
                type, result: calculateStorage, order
            }));

        } else {
            await handleDomainAndCalculation(xml, type, order, dispatch);

        }

        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(checkErrorsAPI(typeErrorApi.gb_letter, e?.message));
        dispatch(onToggleAllowRequestCheckProject(true))
        console.log(e);
    }
};

async function handleDomainAndCalculation(xml, type, order, dispatch) {
    try {
        const domain = await apiGiblab._checkApiDomain();

        if (!domain) return;

        const result = await apiGiblab._sendGiblab(domain, xml);
        if (apiGiblab._errors) {
            dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
        }
        handleResultErrorsAndDispatch(result, xml, type, order, dispatch);

    } catch (error) {
        await dispatch(sendAdditionalRequestGl(xml, async (result) => {
            await dispatch(gitlabMiscalculationCaching({type, result, order, xml}));
        }, () => {
        }));
    }
}

function handleResultErrorsAndDispatch(result, xml, type, order, dispatch) {

    if (!result || result.timout || /[а-яё]/i.test(result)) {
        dispatch(sendAdditionalRequestGl(xml, (result) => {
            dispatch(gitlabMiscalculationCaching({type, result, order, xml}));
        }, () => {
        })).catch(e => {

        })
        return;
    }

    dispatch(gitlabMiscalculationCaching({type, result, order, xml}));
}

async function handleXmlCondition(xml, order, dispatch) {
    if (!xml) return;

    if (xml === "no_cs") {
        const isUpdate = await dispatch(saveOrderProjectDataThunk(false));
        if (!isUpdate) throw new Error('not save project')
        dispatch(setCalculateOrderThunk(order, true));
        // dispatch(changeLoaderStatusAC(false));
    }
}

/*Резервная калькуляция проекта **/
const sendReserveCalculateCuttingThunk = (orderId, successCb, statusTypeThrow = 1) => async (dispatch) => {
    let status = {}
    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Резервная калькуляция проекта...")));
        const responseCutting = await CuttingAPI.getCuttingByOrderId(orderId);
        if (responseCutting?.hasOwnProperty('error') || responseCutting?.error) {
            dispatch(dispatcherErrorThunk(responseCutting))
            return
        }
        if (responseCutting && responseCutting?.link_to_cad) {
            localStorage.setItem(LS_LINK_T0_3D_CARD, responseCutting?.link_to_cad)
        }
        successCb(responseCutting.message_for_calc);
        dispatch(changeLoaderStatusAC(false));

    } catch (error) {
        console.log(error);
        let message = `${error.message}. `;
        dispatch(changeLoaderStatusAC(false));
        dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api: _Service `, error.message));
        dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api! Giblab and additional Cutting`, 'сервер 503'));

    }

}

const sendAdditionalRequestGl = (xml, successCb, rejectCb) => async (dispatch, getState) => {
    let order = getState().order.order;
    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Дополнительная калькуляция проекта...")));
        let regexp = /[а-яё]/i;
        const result = await apiGiblab._Service(xml);

        if (!regexp.test(result)) {
            successCb(result);
            dispatch(changeLoaderStatusAC(false));
            // dispatch(gitlabMiscalculationCaching({ type, result, order, xml }));
            return;
        }
        // dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Резервная калькуляция проекта...")));
        // const responseCutting = await CuttingAPI.getCuttingByOrderId(order.id);
        // if (responseCutting?.hasOwnProperty('error') || responseCutting?.error) {
        //     dispatch(dispatcherErrorThunk(responseCutting))
        //     return
        // }
        // if (responseCutting && responseCutting?.link_to_cad) {
        //     localStorage.setItem(LS_LINK_T0_3D_CARD, responseCutting?.link_to_cad)
        // }
        // successCb(responseCutting.message_for_calc);
        dispatch(changeLoaderStatusAC(false));
        dispatch(sendReserveCalculateCuttingThunk(order.id, successCb));

    } catch (e) {
        dispatch(sendReserveCalculateCuttingThunk(order.id, successCb));
        // dispatch(changeLoaderStatusAC(false));
        // dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api: _Service `, e.message));
        // dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab (_checkApiDomain)`, 'сервер 503'));

    }
}
const gitlabMiscalculationCaching = ({type, result, order, xml = ""}) => async (dispatch, getState) => {

    const version_code = getState().commons.data;
    const client_id = getState().auth.user.id;
    dispatch(onToggleAllowRequestCheckProject(false))
    switch (type) {
        case "cards":
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение карт кроя с АПИ...")));
            apiGiblab.getCards(result, order)
                .then(cards => {
                    dispatch(onToggleAllowRequestCheckProject(true));
                    dispatch(changeLoaderStatusAC(false));
                    if (apiGiblab._errors) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                    }
                    if (cards === false) {
                        throw new Error("");
                    }
                }).catch(e => {
                dispatch(onToggleAllowRequestCheckProject(true));

                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e?.message}]`));

            });
            break;
        case "reports":
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение полного отчета...")));
            apiGiblab.getReport(result, order)
                .then(report => {
                    dispatch(onToggleAllowRequestCheckProject(true));

                    dispatch(changeLoaderStatusAC(false));
                    if (apiGiblab._errors) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                    }
                    if (report === false) {
                        throw new Error("");
                    }
                }).catch(e => {
                dispatch(onToggleAllowRequestCheckProject(true));

                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e?.message}]`));

            });
            break;
        case "blank_html":
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение бланка заказа...")));
            let update_calculate_goods_order = await calculateNumberOfProfilesHandlerAndEdge(order, dispatch);
            let update_order_calc = {...update_calculate_goods_order}

            const calculate_response = await api.calculateOrder(update_order_calc);
            let processing_edge_calculate = getCalculateShippingProcessingEdge({
                ...update_order_calc,
                calculate: {material: calculate_response?.material}
            });
            let order_calculate_processing = {...order, calculate: {...processing_edge_calculate}}
            apiGiblab.getBlank(result, order_calculate_processing, type)
                .then(async blank => {
                    dispatch(changeLoaderStatusAC(false));
                    if (apiGiblab._errors) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                    }
                    if (blank === false) {
                        throw new Error("");
                    }
                }).catch(e => {
                dispatch(onToggleAllowRequestCheckProject(true));

                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e?.message}]`));

            });
            break;
        case "blank":
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение бланка заказа...")));
            apiGiblab.getBlank(result, order)
                .then(blank => {
                    dispatch(onToggleAllowRequestCheckProject(true));

                    dispatch(changeLoaderStatusAC(false));
                    if (apiGiblab._errors) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                    }
                    if (blank === false) {
                        throw new Error("");
                    }
                }).catch(e => {
                dispatch(onToggleAllowRequestCheckProject(true));

                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e?.message}]`));

            });
            break;
        case "calc":
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение обновленной калькуляции проекта...")));
            apiGiblab.getCalc(result, order)
                .then(async (calc) => {

                    await manipulateSessionCalculate.set(result);
                    if (calc?.api_error_calc) {
                        dispatch(changeAlertStatusAC(true, calc?.api_error_calc));
                        throw new Error(calc?.api_error_calc);
                        return false
                    }
                    if (calc && calc.hasOwnProperty("error")) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                        throw new Error(calc.error);
                        return false
                    }
                    if (calc === false) {
                        throw new Error(apiGiblab._getErrors());
                        return false
                    }

                    dispatch(onToggleAllowRequestCheckProject(true));


                    if (calc?.cutting_card_in) {
                        await dispatch(dispatchedCuttingCardThunk(calc.id))
                    } else {
                        dispatch(setCuttingCardInProject(calc?.cutting_card));
                    }
                    dispatch(saveOrderProjectDataThunk(false, false, false, order.packing))
                        .then(res => {
                            dispatch(setCalculateOrderThunk(calc, true));
                            dispatch(changeLoaderStatusAC(false));

                        })
                        .catch(e => {
                            throw new Error('not save project')
                        })

                }).catch(e => {
                dispatch(changeLoaderStatusAC(false));
                dispatch(onToggleAllowRequestCheckProject(true));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e?.message}]`, e.message || ""));

            });
            break;
        case "cutting": {
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение карты раскроя")));
            apiGiblab.getCutting(result, order, true)
                .then(async (res) => {
                    await manipulateSessionCalculate.set(result);
                    if (res && !res.hasOwnProperty("error")) {
                        const imagesApi = new apiImages();
                        imagesApi.getCuttingPreviews(res).then(async (res) => dispatch(setCuttingPreviewAC(res)));
                        dispatch(setOrderProjectDataAC(res))
                        dispatch(saveOrderProjectDataThunk(true, false, true))
                            .then(async response => {
                                if (res?.cutting_card_in) {
                                    await dispatch(dispatchedCuttingCardThunk(order.id))
                                } else {
                                    let cutting = getState().order.order.cutting_card
                                    await dispatch(dispatchCuttingCardOwnerUiDefThunk(cutting))
                                }
                                dispatch(actionsCuttingChart.onToggleCuttingPage(true));
                            })
                            .catch(e => {
                                throw new Error('not save project')
                            })
                    }
                    if (res.hasOwnProperty("error")) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                        throw {
                            message: res.error
                        };
                    }


                    dispatch(onToggleAllowRequestCheckProject(true));
                    dispatch(changeLoaderStatusAC(false));
                }).catch(async e => {
                dispatch(onToggleAllowRequestCheckProject(true));
                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e.message}]`, `${e.message || ""}`));
                return false;

            });
            break;
        }
        case "get-cuttings": {
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение карты раскроя")));
            apiGiblab.getCutting(result, order, true)
                .then(async (res) => {
                    await manipulateSessionCalculate.set(result);
                    if (res && !res.hasOwnProperty("error")) {
                        dispatch(setOrderProjectDataAC(res));
                        if (res?.cutting_card_in) await dispatch(dispatchedCuttingCardThunk(order.id))
                        const imagesApi = new apiImages();
                        imagesApi.getCuttingPreviews(res).then(async (res) => dispatch(setCuttingPreviewAC(res)));
                        let isSave = await dispatch(saveOrderProjectDataThunk(true, false, true))
                        if (!isSave) throw new Error('not save project')
                        const firm = localStorage.getItem('ifurn_service_main_user_firm')
                        window.open(`${api._baseIfurn}/cutting?client=${client_id}&order=${version_code.code}&firm=${firm}&clean=1`, '_self');

                        dispatch(changeLoaderStatusAC(false));
                    }
                    if (res.hasOwnProperty("error")) {
                        dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                        throw {
                            message: res.error
                        };
                    }
                    dispatch(onToggleAllowRequestCheckProject(true));

                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderStatusAC(false));
                }).catch(async e => {
                dispatch(onToggleAllowRequestCheckProject(true));
                dispatch(changeLoaderStatusAC(false));
                dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, type:${type} [message=${e.message}]`, `${e.message || ""}`));
                return false;

            });
            break;
        }
        case "short_order_form_html": {
            let logo = getState().commons?.ruleSettingsProject?.logoUrl;
            let company_name = getState().commons?.ruleSettingsProject?.name
            let goods_service_for_part_by_firm = getState()?.order?.additional_goods_service?.GoodsServiceForPartByFirm
            dispatch(changeLoaderStatusAC(true));
            dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение обновленной калькуляции проекта...")));
            let calc = await apiGiblab.getCalc(result, order);
            await manipulateSessionCalculate.set(result);
            if (calc?.api_error_calc || calc && calc.hasOwnProperty("error") || !calc) {
                let error = calc?.api_error_calc || apiGiblab._getErrors() || apiGiblab._getErrors();
                dispatch(changeAlertStatusAC(true, error));
                dispatch(changeLoaderStatusAC(false));
                throw new Error(error);
                return false
            }
            dispatch(setOrderProjectDataAC(calc))
            dispatch(setOrderCopyProject(calc));
            const isSave = await dispatch(saveOrderProjectDataThunk(false, false, false, order.packing));
            if (isSave) {
                if (getState().commons.errors) {
                    dispatch(changeCheckProjectModalValue("isOpen", true));
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));
                    return false;
                }
                let update_calculate_goods_order = await calculateNumberOfProfilesHandlerAndEdge(calc, dispatch);
                let update_order_calc = {...update_calculate_goods_order}

                const calculate_response = await api.calculateOrder(update_order_calc);
                if (api?._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));

                    return false;
                }
                if (calculate_response.error) {
                    dispatch(getCheckProjectErrorsThunk(true, true));
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));

                    return false;
                }
                if (calculate_response.api_error_calc) {
                    dispatch(setProjectCalcErrorsAC(calculate_response.api_error_calc));
                    dispatch(changeCheckProjectModalValue("isOpen", true));
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));

                    return false;
                }
                if (update_order_calc?.cutting_card_in) {
                    const cutting_card = await api._getCuttingCard(order?.id);
                    if (cutting_card?.hasOwnProperty('error') || api._errors) {
                        let error_msg = api._getErrors() || cutting_card?.error;
                        throw Error(error_msg);
                        dispatch(dispatcherErrorThunk(error_msg))
                        return
                    }
                    if (!cutting_card) {
                        dispatch(dispatcherErrorThunk({error: getT("Не удалось получить карты кроя")}))
                        return
                    }
                    let cutting = cutting_card ? cutting_card?.cutting_card : [];

                    update_order_calc = {
                        ...update_calculate_goods_order,
                        cutting_card: cutting
                    }


                    dispatch(setCuttingCardInProject(cutting))
                }
                let processing_edge_calculate = getCalculateShippingProcessingEdge({
                    ...update_order_calc,
                    calculate: {material: calculate_response?.material}
                });
                const currencies = getState().commons?.currencies || [];
                const currency = currencies?.find(item => Number(item.id) === Number(order?.currency))?.name ?? '';


                let payload = {
                    ...update_order_calc,
                    calculate: {
                        ...update_order_calc?.calculate,
                        ...calculate_response,
                        ...processing_edge_calculate
                    },
                    currency: currency,
                    band_calculate: calculate_response?.material?.filter(item => item.type === "band")
                }
                const content_html = new ShortOrderHtml().generateContentHtml({
                    order: payload,
                    full: true,
                    logo: logo,
                    goods_service_for_part_by_firm: goods_service_for_part_by_firm
                });
                shortOrderFormWindowHtml.openWindowPrint({content: content_html, order: payload});
                dispatch(changeLoaderStatusAC(false));
            } else {
                throw new Error('not save project')
            }
            break;
        }
        default:
            // dispatch(changeLoaderStatusAC(false));
            break;
    }

};
export const getCheckProjectErrorsThunk = (withLoader = false, withOpen = false, parts = null) => async (dispatch, getState) => {
    const state = getState();
    let order = state.order.order;
    const {commons: {errors, errors_service: errorsService}} = state;

    if (parts) {
        order = {...order, part: parts};
    }

    if (withLoader) {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Проверяем проект на наличие ошибок...")));
    }

    try {
        const res = await api.checkProject(order);

        if (res) {
            compareCheckProjectAndDispatch(errors?.parts, res.parts, setProjectErrorsAC, dispatch);
            const part_errors = Object.keys(res.parts).map(Number);
            compareCheckProjectAndDispatch(errorsService?.parts, part_errors, part_errors => setServiceMainErrorsDataAC("parts", part_errors), dispatch);
            compareCheckProjectAndDispatch(errorsService?.xnc, res.xnc, xnc => setServiceMainErrorsDataAC("xnc", xnc), dispatch);

            if (withOpen) {
                dispatch(changeCheckProjectModalValue("isOpen", true));
            }
        } else {
            handleCheckProjectResponseErrors(api, errors, errorsService, dispatch);
        }

        if (withLoader) {
            dispatch(changeLoaderStatusAC(false));
        }
    } catch (e) {
        dispatch(checkErrorsAPI('', `api:/check-project/, method:post `, `Message = ${e?.message}`));
        console.log(e);
        throw e;
    }
};

function compareCheckProjectAndDispatch(current, updated, actionCreator, dispatch) {

    if (JSON.stringify(current) !== JSON.stringify(updated)) {
        dispatch(actionCreator(updated));
    }
}

function handleCheckProjectResponseErrors(api, errors, errorsService, dispatch) {
    if (api._errors) {
        dispatch(changeAlertStatusAC(true, api._getErrors()));
    }
    if (errors !== null) {
        dispatch(setProjectErrorsAC(null));
    }
    if (errorsService.parts?.length || errorsService.parts?.length) {
        dispatch(setServiceMainErrorsDataAC("parts", []));
        dispatch(setServiceMainErrorsDataAC("xnc", []));
    }
}


export const getRegionsDataThunk = () => (dispatch, getState) => {

    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем необходимые данные...")));
    api.getRegions()
        .then(res => {
            if (res) {
                dispatch(setRegionsDataAC(res));
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            dispatch(checkErrorsAPI("", `get regions`));
            console.log(e);
        });
};
export const getStoresDataThunk = () => async (dispatch, getState) => {

    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем список точек производства, продажи, выдачи...")));
    try {
        const response = await api.getStores();
        if (api._errors) {
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeAlertStatusAC(true, api._getErrors()));
            return Promise.resolve(false)
        }
        if (response) {
            dispatch(setFirmsOrdersStoresDataAC(response));
        }
        dispatch(changeLoaderStatusAC(false));
        return Promise.resolve(true)
    } catch (e) {
        dispatch(checkErrorsAPI('', `api:/confirm/store/, method:get`, `список точек производства, продажи, выдачи`));
        return Promise.resolve(false)
    }
};
const ORDER_BLANK_SHORT_WITHOUT_ACTIONS = {
    "order_blank_short_without": "order_blank_short_only",
    "order_blank_short_without_html": "order_blank_short_only_html"
};
export const getGiblablDataAPIThunk = (action, part_id = null, withSave = true) => (dispatch, getState) => {
    const orderCopy = getState()?.order?.order;
    const order = orderCopy ? {...orderCopy} : {};
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем данные с сервера...")));
    // ----------------------------
    // const printHtml = new PrintHtml();
    // ----------------------------

    if (action === "order_blank_short_without" || action === "order_blank_short_without_html") {
        order.calculate = [];
        order.calculate_discounts = [];
        order.calculate_types = [];
        action = ORDER_BLANK_SHORT_WITHOUT_ACTIONS[action];
    }

    if (!order.calculate_vals || order.calculate_vals.length === 0) {
        dispatch(getCheckProjectErrorsThunk(true, false));
        dispatch(saveOrderProjectDataThunk()).catch(e => {
            throw new Error()
        })
    } else if (withSave) {
        dispatch(saveOrderProjectDataThunk(false)).catch(e => {
            throw new Error()
        })
    }
    // }
    // if (withSave) {
    //     dispatch(saveOrderProjectDataThunk(false));
    // }

    if (getState().commons.errors) {
        dispatch(changeCheckProjectModalValue("isOpen", true));
        dispatch(changeLoaderStatusAC(false));
        return false;
    }

    api.getGiblabData(order, action, part_id)
        .then(res => {

            if (!res) return false;
            if (res.response && res.response.res_error) {
                dispatch(changeAlertStatusAC(res.response.error));
                dispatch(changeLoaderStatusAC(false));
                return false;
            }

            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
            }

            switch (action) {
                case "pdf":
                    if (res.response && res.response.files && res.response.files.length > 0) {
                        window.open(res.response.files[1].link);
                    }
                    break;
                case "xnc":
                    if (res.response && res.response.files && res.response.files.length > 0) {
                        window.open(res.response.files[1].link);
                    }
                    break;
                case "cards":
                    if (res.response && res.response.files && res.response.files.length > 0) {
                        res.response.files.forEach((e) => {
                            if (e.format === "pdf") {
                                window.open(e.link);
                            }
                        });
                    }
                    break;
                case "gibsaw":
                    if (res.response && res.response.files && res.response.zip_link) {
                        window.open(res.response.zip_link);
                    }
                    break;
                case "order_blank":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "material_list":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "order_blank_short":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "order_blank_short_only":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "order_blank_short_only_html":
                    if (res?.base) {
                        const title = `${order?.code} / № ${order?.id}`;
                        blankHtml.renderBase64ToHtmlWindow(res?.base, title, order);
                    }
                    break;
                case "order_blank_short_without_html":
                case "order_blank_short_html":
                    if (res?.base) {
                        const title = `${order?.code} / № ${order?.id}`;
                        blankHtml.renderBase64ToHtmlWindow(res?.base, title, order);
                    }
                    break;
                case "detail_print":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "ifp_xnc":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "get_ifp_project":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "gibsaw_ftp":
                    if (res.response && res.response.link) {
                        window.open(res.response.link);
                    }
                    break;
                case "save_project":
                    if (res.response && res.response.link) {
                        let link = document.createElement("a");
                        link.setAttribute("href", res.response.link);
                        link.setAttribute("target", "_blank");
                        link.setAttribute("rel", "noopener noreferrer");
                        link.setAttribute("download", "file.project");
                        window.onload = link.click();
                    }
                    break;
                default:
                    dispatch(changeLoaderStatusAC(false));
                    break;

            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            dispatch(checkErrorsAPI(typeErrorApi.gb_letter, `api:giblab, method:post, action:${action} `));
            console.log(e);
        });
};
const _constructorImport = (check) => {
    const check_goods = {
        material: [], band: [], goods: []
    };
    const new_goods = {
        new1: {
            sheet: [], band: [], simple: []
        }
    };
    for (let i = 0; i < Object.keys(check).length; i++) {

        let e = check["import_porject_" + i];

        if (e.sheet) {
            check_goods.material = check_goods.material.concat(e.sheet);
            new_goods.new1.sheet = new_goods.new1.sheet.concat(e.sheet.map(m => {
                return {
                    code_project: m.CODE, ifp_code: null, project: "import_porject_" + i
                };
            }));
        }
        if (e.band) {
            check_goods.band = check_goods.band.concat(e.band);
            new_goods.new1.band = new_goods.new1.band.concat(e.band.map(m => {
                return {
                    code_project: m.CODE, ifp_code: null, project: "import_porject_" + i
                };
            }));
        }
        if (e.simple) {
            check_goods.goods = check_goods.goods.concat(e.simple);
            new_goods.new1.simple = new_goods.new1.simple.concat(e.simple.map(m => {
                return {
                    code_project: m.CODE, ifp_code: null, project: "import_porject_" + i
                };
            }));
        }

    }

    return {
        check_goods: check_goods, new_goods: new_goods
    };
};

export const importProjectsThunk = (files, viyar = null, order_id = null) => async (dispatch, getState) => {
    const version = getState().commons.data.version;
    const order = getState().order.order;

    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем проекты на проверку...")));
        let dt = viyar ? [{name: "viyar", value: viyar}] : [];
        if (order_id) {
            dt = [...dt, {name: 'order', value: order_id}]
        }
        let resSave = await api.saveProject(version, order);
        utilLogger('resSaveProject', resSave);

        if (!resSave.is_save) {
            dispatch(dispatcherErrorThunk({error: `Not saved project!`}));
            return
        }
        let resImport = await api.importProject(files, "import", dt);
        utilLogger('resImport', resImport);
        if (!resImport) {
            dispatch(changeLoaderStatusAC(false));
            return;
        }
        if (api._errors) {
            dispatch(dispatcherErrorThunk({error: api._getErrors()}));
            return;
        }

        if (resImport.hasOwnProperty("viyar") && resImport.hasOwnProperty("error")) {
            dispatch(onSetFileSetClarificationDT(resImport));
        }
        if (resImport.status === "bad" && resImport.message) {
            dispatch(changeAlertStatusAC(true, resImport.message));
        }
        if (resImport.check && resImport.projects) {
            const importData = _constructorImport(resImport.check);
            dispatch(changeImportProjectModalValue("data", {
                change_goods: importData.check_goods,
                new_goods: importData.new_goods,
                projects: resImport.projects,
                projects_name: resImport.projects_name,
                need_change: true
            }));
        }
        let require_choose_material = resImport?.require_choose_material ?? false;
        dispatch(changeImportProjectModalValue('require_choose_material', require_choose_material));
        if (Number(resImport?.combined_from_ifp)) {
            dispatch(setOrderProjectDataAC(resImport?.order))
            dispatch(changeImportProjectModalValue('isOpen', false))
            dispatch(changeImportProjectModalValue('data', null))
            dispatch(changeImportProjectModalValue('arr', false))
            dispatch(changeImportProjectModalValue('name_part_put', null))
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
export const _importProjectsThunk = (files, viyar = null, order_id = null) => async (dispatch, getState) => {
    const version = getState().commons.data.version;
    const order = getState().order.order;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем проекты на проверку...")));
    let dt = viyar ? [{name: "viyar", value: viyar}] : [];
    if (order_id) {
        dt = [...dt, {name: 'order', value: order_id}]
    }
    let res = await api.saveProject(version, order);
    utilLogger('res', res);
    // let importRes = await api.importProject(version, order_id);
    //
    api.importProject(files, "import", dt)
        .then(res => {
            if (res) {

                if (res.hasOwnProperty("viyar") && res.hasOwnProperty("error")) {
                    dispatch(onSetFileSetClarificationDT(res));
                }
                if (res.status === "bad" && res.message) {
                    dispatch(changeAlertStatusAC(true, res.message));
                }
                if (res.check && res.projects) {
                    const importData = _constructorImport(res.check);
                    dispatch(changeImportProjectModalValue("data", {
                        change_goods: importData.check_goods,
                        new_goods: importData.new_goods,
                        projects: res.projects,
                        projects_name: res.projects_name,
                        need_change: true
                    }));
                }
                if (Number(res?.combined_from_ifp)) {
                    dispatch(setOrderProjectDataAC(res?.order))
                    dispatch(changeImportProjectModalValue('isOpen', false))
                    dispatch(changeImportProjectModalValue('data', null))
                    dispatch(changeImportProjectModalValue('arr', false))
                    dispatch(changeImportProjectModalValue('name_part_put', null))
                }
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            dispatch(checkErrorsAPI('', `api:import, method:post`));
            console.log(e);
        });
};
export const importProjectsChangeThunk = (files) => async (dispatch, getState) => {

    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем проекты на проверку...")));
        const data = getState().modal.importProject.data;
        const name_part_put = getState().modal.importProject.name_part_put;
        const arr = getState().modal.importProject.arr;
        const order = getState().order.order;
        const version = getState().commons.data.version;
        const code = getState().commons.data.code;
        const client = getState().auth.user.id;

        let new_goods_change = {};
        data.new_goods.new1.sheet.forEach(e => {
            new_goods_change[e.project] = {
                band: [], sheet: [], simple: []
            };
        });
        data.new_goods.new1.band.forEach(e => {
            new_goods_change[e.project] = {
                band: [], sheet: [], simple: []
            };
        });
        data.new_goods.new1.simple.forEach(e => {
            new_goods_change[e.project] = {
                band: [], sheet: [], simple: []
            };
        });

        data.new_goods.new1.sheet.forEach(e => {
            if (e.ifp_code == null) {
                e.ifp_code = "new_project";
            }
            new_goods_change[e.project].sheet.push(e);
        });
        data.new_goods.new1.band.forEach(e => {
            if (e.ifp_code == null) {
                e.ifp_code = "new_project";
            }
            new_goods_change[e.project].band.push(e);
        });
        data.new_goods.new1.simple.forEach(e => {
            if (e.ifp_code == null) {
                e.ifp_code = "new_project";
            }
            new_goods_change[e.project].simple.push(e);
        });

        let resImport = await api.importProjectChanges(data.projects, new_goods_change, order, name_part_put, data.projects_name, arr);
        if (api._errors) {
            dispatch(dispatcherErrorThunk({error: api._getErrors()}));
            return;
        }
        if (resImport.status === "bad" && resImport.message) {
            dispatch(changeAlertStatusAC(true, resImport.message));

        }
        if (resImport.order) {
            let res_order = api._transformProject(resImport.order)
            await api.saveProject(version, res_order);
            dispatch(setOrderProjectDataThunk())
            dispatch(changeImportProjectModalValue("isOpen", false));
            dispatch(changeImportProjectModalValue("data", null));
            dispatch(changeImportProjectModalValue("name_part_put", null));
            dispatch(changeImportProjectModalValue("arr", false));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e.message);
        dispatch(dispatcherErrorThunk(e));
    }


    //
    // api.importProjectChanges(data.projects, new_goods_change, order, name_part_put, data.projects_name, arr)
    //     .then(async res => {
    //         if (res) {
    //             if (res.status === "bad" && res.message) {
    //                 dispatch(changeAlertStatusAC(true, res.message));
    //             }
    //             if (res.order) {
    //                 let res_order = api._transformProject(res.order)
    //                 await api.saveProject(version, res_order);
    //                 dispatch(setOrderProjectDataThunk())
    //                 dispatch(changeImportProjectModalValue("isOpen", false));
    //                 dispatch(changeImportProjectModalValue("data", null));
    //                 dispatch(changeImportProjectModalValue("name_part_put", null));
    //                 dispatch(changeImportProjectModalValue("arr", false));
    //             }
    //         } else {
    //             if (api._errors) {
    //                 dispatch(changeAlertStatusAC(true, api._getErrors()));
    //             }
    //         }
    //         dispatch(changeLoaderStatusAC(false));
    //     })
    //     .catch(e => {
    //         dispatch(checkErrorsAPI('', "api:/import, method: post", `Message = ${e?.message}`));
    //         console.log(e);
    //     });
};
export const _importProjectsChangeThunk = (files) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем проекты на проверку...")));

    const data = getState().modal.importProject.data;
    const name_part_put = getState().modal.importProject.name_part_put;
    const arr = getState().modal.importProject.arr;
    const order = getState().order.order;
    const version = getState().commons.data.version;
    const code = getState().commons.data.code;
    const client = getState().auth.user.id;

    let new_goods_change = {};
    data.new_goods.new1.sheet.forEach(e => {
        new_goods_change[e.project] = {
            band: [], sheet: [], simple: []
        };
    });
    data.new_goods.new1.band.forEach(e => {
        new_goods_change[e.project] = {
            band: [], sheet: [], simple: []
        };
    });
    data.new_goods.new1.simple.forEach(e => {
        new_goods_change[e.project] = {
            band: [], sheet: [], simple: []
        };
    });

    data.new_goods.new1.sheet.forEach(e => {
        if (e.ifp_code == null) {
            e.ifp_code = "new_project";
        }
        new_goods_change[e.project].sheet.push(e);
    });
    data.new_goods.new1.band.forEach(e => {
        if (e.ifp_code == null) {
            e.ifp_code = "new_project";
        }
        new_goods_change[e.project].band.push(e);
    });
    data.new_goods.new1.simple.forEach(e => {
        if (e.ifp_code == null) {
            e.ifp_code = "new_project";
        }
        new_goods_change[e.project].simple.push(e);
    });
    api.importProjectChanges(data.projects, new_goods_change, order, name_part_put, data.projects_name, arr)
        .then(async res => {
            if (res) {
                if (res.status === "bad" && res.message) {
                    dispatch(changeAlertStatusAC(true, res.message));
                }
                if (res.order) {
                    let res_order = api._transformProject(res.order)
                    await api.saveProject(version, res_order);
                    dispatch(setOrderProjectDataThunk())
                    dispatch(changeImportProjectModalValue("isOpen", false));
                    dispatch(changeImportProjectModalValue("data", null));
                    dispatch(changeImportProjectModalValue("name_part_put", null));
                    dispatch(changeImportProjectModalValue("arr", false));
                }
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            dispatch(checkErrorsAPI('', "api:/import, method: post", `Message = ${e?.message}`));
            console.log(e);
        });
};


export const onSendCalcShoppingGb = (result) => async (dispatch, getState) => {
    const order_store = getState().order.order
    dispatch(wrapperSendCalcShoppingGb(result, async () => {
        if (order_store?.cutting_card_in) await dispatch(dispatchedCuttingCardThunk(order_store.id))
        dispatch(actionsCuttingChart.onToggleCuttingPage(false));
        manipulateSessionCalculate.delete()
    }));
};


export const wrapperSendCalcShoppingGb = (message, cb = false) => async (dispatch, getState) => {
    const order = getState().order.order;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получение обновленной калькуляции проекта...")));
    apiGiblab.getCalc(message, order)
        .then(calc => {
            dispatch(changeLoaderMessageAC(false));
            // dispatch(changeLoaderStatusAC(false));
            if (apiGiblab._errors) {
                dispatch(changeLoaderStatusAC(false));
                dispatch(changeAlertStatusAC(true, apiGiblab._getErrors()));
                return
            }

            dispatch(saveOrderProjectDataThunk(false))
                .then(async res => {
                    let has_is_cutting_card = calc?.cutting_card && !Array.isArray(calc?.cutting_card) && (calc?.cutting_card instanceof Object);
                    if (has_is_cutting_card) {
                        dispatch(setCuttingCardInProject(calc.cutting_card))

                    }
                    if (calc?.cutting_card_in && !has_is_cutting_card) {
                        const cutting_card = await api._getCuttingCard(order?.id);
                        if (cutting_card?.hasOwnProperty('error') || api._errors) {
                            let error_msg = api._getErrors() || cutting_card?.error;
                            throw Error(error_msg);
                            dispatch(dispatcherErrorThunk(error_msg))
                        }
                        if (!cutting_card) {
                            dispatch(dispatcherErrorThunk({error: getT("Не удалось получить карты кроя")}))
                        }
                        let cutting = cutting_card ? cutting_card?.cutting_card : [];
                        dispatch(setCuttingCardInProject(cutting))
                    }
                    dispatch(setCalculateOrderThunk(calc, true));
                    dispatch(changeLoaderStatusAC(false));
                }).catch(e => {
                throw new Error('not save project')
            })
        }).catch(e => {
        dispatch(checkErrorsAPI(typeErrorApi.gb_letter, "api: giblab, method: post, action: file_optimized_from_giblab_calc"));
    });
};

export const typeErrorApi = {
    save: "save", gb_letter: "gb_letter"
};
export const checkErrorsAPI = (type = "", optionError = "", senderMessage = "") => async (dispatch, getState) => {
    const order = getState().order.order;
    switch (type) {
        case typeErrorApi.save: {
            dispatch(changeAlertStatusAC(true, `${optionError} ${getTranslateMessageAPI("Произошел сбой при сохранении. Данные на сервере не сохранились")}`));
            api._sendLetter({text: `Заказ = ${order.id}, пользователь  = ${order.client_name}, фирма = ${order.firm} - сообщение ошибки = ${senderMessage}, optionError = ${optionError}`})
                .then((res) => {
                    // console.log("_sendLetter", res);
                })
                .catch(e => {
                    console.log("catch e", e);
                });
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));
            break;
        }
        case typeErrorApi.gb_letter: {
            dispatch(changeAlertStatusAC(true, `${optionError} ${getTranslateMessageAPI("Проблема с калькуляцией в GibService, обратитесь к администраторам!")}`));
            dispatch(changeLoaderStatusAC(false));
            // api._sendLetter({text: `Заказ = ${order.id}, пользователь  = ${order.client_name}, фирма = ${order.firm} - проблема с гиблаб, сообщение ошибки = ${senderMessage}`})
            //     .then((res) => {
            //         // console.log("_sendLetter", res);
            //     })
            //     .catch(e => {
            //         console.log("catch e", e);
            //     });
            break;
        }
        default: {
            dispatch(changeAlertStatusAC(true, `${optionError}  ${getTranslateMessageAPI("Произошел сбой, попробуйте позже!")}`));
            // api._sendLetter({text: `Заказ = ${order.id}, пользователь  = ${order.client_name}, фирма = ${order.firm} - сообщение ошибки = ${senderMessage}, optionError = ${optionError}`})
            //     .then((res) => {
            //         // console.log("_sendLetter", res);
            //     })
            //     .catch(e => {
            //         console.log("catch e", e);
            //     });
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));
        }
    }
};


export const fetchingMaterialExportBasis = () => async dispatch => {
    try {
        const material_export = await api._materialExportBasis();
        let payload = material_export ? material_export : null;
        dispatch(onToggleMaterialExportBasis(payload))
    } catch (e) {

    }
}


/**
 * checkProjectAndSaveBeforeOpenPacking проверка на ошибки и сохранение проекта с упаковками и открытие стр упаковки.
 *
 * @returns  {promise}  - true ar false
 */
export const checkProjectAndSaveBeforeOpenPacking = () => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    const state = getState();
    const version = state.commons.data.version;
    const order = state.order.order;
    const {commons: {errors, errors_service: errorsService}} = state;
    try {
        const res = await api.checkProject(order);

        if (res) {
            compareCheckProjectAndDispatch(errors?.parts, res.parts, setProjectErrorsAC, dispatch);
            const part_errors = Object.keys(res.parts).map(Number);
            compareCheckProjectAndDispatch(errorsService?.parts, part_errors, part_errors => setServiceMainErrorsDataAC("parts", part_errors), dispatch);
            compareCheckProjectAndDispatch(errorsService?.xnc, res.xnc, xnc => setServiceMainErrorsDataAC("xnc", xnc), dispatch);
            dispatch(changeCheckProjectModalValue("isOpen", true));
            dispatch(changeLoaderStatusAC(false));
            return new Promise((_, rej) => rej(false))
        }
        const save_response = await api.saveProject(version, order);
        if (!save_response?.is_save) {
            if (api._errors) {
                throw api._getErrors();
            } else {
                checkErrorsAPI(typeErrorApi.save, "api:  /projects/${version}, M:PUT", `Message = ${save_response?.res}, api_errors=${api._getErrors()}`);
                throw getTranslateMessageAPI("Произошел сбой при сохранении. Данные на сервере не сохранились");
            }
        }
        dispatch(changeLoaderStatusAC(false));
        return new Promise((res) => res(true))
    } catch (e) {
        dispatch(changeAlertStatusAC(true, e?.message));
        dispatch(changeLoaderStatusAC(false));
        return new Promise((_, rej) => rej(false))
    }
}
export const setNewsAC = (payload) => {
    return {
        type: NEWS_SET_LIST, payload: payload
    }
}
export const getNewsThunk = (page = 1) => async dispatch => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const response_news = await api._getNews(page);
        if (response_news.hasOwnProperty('error')) {
            dispatch(changeAlertStatusAC(true, response_news.error));
            // dispatch(setNewsAC(NEWS_INITIAL_VALUES));
            return
        }
        dispatch(setNewsAC(response_news));
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        dispatch(setNewsAC(NEWS_INITIAL_VALUES));
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(changeAlertStatusAC(true, msg));
    }
}
export default CommonReducer;
