import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC, checkErrorsAPI} from "./CommonReducer";
import ApiNewService from "../../api/apiNew";
import {getDate, getTranslateMessageAPI, random} from "../../helpers/helpers";
import ApiService from "../../api/api";
import {addOrderMaterialAC} from "./OrderReducer";
import {LS_LANG} from "../../constants";


const SET_MATERIALS_TREE_DATA = 'SET_MATERIALS_TREE_DATA';
const SET_ACTIVE_MATERIALS_CATEGORY = 'SET_ACTIVE_MATERIALS_CATEGORY';
const SET_MATERIALS_DATA = 'SET_MATERIALS_DATA';
const CHANGE_MATERIALS_SERACH_INPUT_DATA = 'CHANGE_MATERIALS_SERACH_INPUT_DATA';
const CHANGE_FILTERS_MATERIALS_VALUES_DATA = 'CHANGE_FILTERS_MATERIALS_VALUES_DATA';
const CLEAN_MATERIALS_FILTERS_VALUES = 'CLEAN_MATERIALS_FILTERS_VALUES';
const CHANGE_MATERIALS_CLIENT_ADD_FORM_VALUES = 'CHANGE_MATERIALS_CLIENT_ADD_FORM_VALUES';
const CHANGE_MATERIALS_CLIENT_PART_ADD_FORM_VALUES = 'CHANGE_MATERIALS_CLIENT_PART_ADD_FORM_VALUES';
const CLEAN_MATERIALS_PART_ADD_FORM = 'CLEAN_MATERIALS_PART_ADD_FORM';
const SET_PRODUCERS_DATA = 'SET_PRODUCERS_DATA';
const CLEAN_MATERIALS_ADD_FORM = 'CLEAN_MATERIALS_ADD_FORM';

const IS_STATE_FILTER_CNC_OVERRIDE = "IS_STATE_FILTER_CNC_OVERRIDE";
const MATERIAL_SET_REMAINS_STOCK_DT = 'MATERIAL/SET_REMAINS_STOCK_DT'
const MATERIAL_DELETE_REMAINS_STOCK_DT = 'MATERIAL/DELETE_REMAINS_STOCK_DT'
const MATERIAL_DELETE_PLANE_STOCK_DT = 'MATERIAL/DELETE_PLANE_STOCK_DT'
const MATERIAL_UPDATE_PLANE_STOCK_DT = 'MATERIAL/UPDATE_PLANE_STOCK_DT'
const MATERIAL_UPDATE_PRICE_DT = 'MATERIAL/UPDATE_PRICE_STOCK_DT'
const MATERIAL_DELETE_PRICE_DT = 'MATERIAL/DELETE_PRICE_STOCK_DT'

const newApi = new ApiNewService();
const api = new ApiService();


export const _constructorMaterial = (data, id, firm = localStorage.getItem('ifurn_service_main_user_firm')) => {
    return {
        ...data,
        digitCode: id,
        // digitCode: data.digitCode,
        part: data.typeMaterial === 'part' ? 1 : 0,
        band: data.typeMaterial === 'band' ? 1 : 0,
        code: "code_" + data.digitCode,
        d_update: getDate(),
        firm: firm,
        // goods: data.digitCode,
        goods: id,
        goods_firm_code_id: null,
        // goods_id: data.digitCode,
        goods_id: id,
        additional: {
            triml: '',
            trimw: '',
            direction: '',
            active: 1,
            turn: '',
            hard_cut: ''
        },
        clientMaterial: true,
        unit: data.typeMaterial === "part" ? "6" : "5",
        type_material: data.addedType,
        translate: {
            "ua": null,
            "ru": null,
            "pl": null,
            "ro": null,
            "en": null,
            "he":null,
            "de":null,
            "lv":null,
            "lt": null,
            "sk": null,
            "et":null,
            [localStorage.getItem(LS_LANG)]: data.name,
        },
        name: data.name,
        // ru: data.name,
        // en: null,
        // ro: null,
        // ua: null,
        user_code_material_1c: data.user_code_material_1c,
        service: null,
        structure: null,
        text: null,
        tovar: null,
        delivery_time: null,
        x: data.typeMaterial === 'band' ? 10000 : data.x,
        drops: data.typeMaterial === 'band' ? 1 : data.drop,
        // id: data.digitCode,
        id: id,
        type: data.typeMaterial,
    }
}

let initialState = {
    tree: [],
    activeCategory: {
        id: null,
        name: 'Все материалы'
    },
    producers: [],
    materials: {
        count: 0,
        data: []
    },
    filters: {
        code: null,
        x: null,
        y: null,
        z: null,
        producer: null,
        name: null
    },
    clientMaterial: {
        digitCode: '1' + String(random(0, 12, 0)),
        goods_id: '',
        typeMaterial: 'part',
        addedType: '',
        part: '',
        band: '',
        name: 'Материал #' + random(2, 2, 1),
        description: '',
        currency: '',
        price: '',
        drop: null,
        producer: '10', // default NO Name
        type_material: '',
        unit: '',
        mat_calc_type: 1,
        x: '',
        y: '',
        z: '',
        user_code_material_1c: '',
        isEdit: '',
        editMaterialId: '',
        isDavalchMaterial: false,
        parts: []
    },
    clientMaterialPart: {
        x: '',
        y: '',
        count: ''
    },
    search: null,
    filter_cnc_override: false
};

const MaterialsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATERIALS_TREE_DATA:
            return {
                ...state,
                tree: action.tree
            };
        case SET_ACTIVE_MATERIALS_CATEGORY:
            return {
                ...state,
                activeCategory: {
                    id: action.id,
                    name: action.name
                }
            };
        case SET_MATERIALS_DATA:
            return {
                ...state,
                materials: {
                    count: action.count,
                    data: action.data
                }
            };
        case CHANGE_MATERIALS_SERACH_INPUT_DATA:
            return {
                ...state,
                search: action.search
            };
        case CHANGE_FILTERS_MATERIALS_VALUES_DATA:
            let filters = {...state.filters};
            filters[action.input] = action.value;
            return {
                ...state,
                filters: filters
            };
        case CHANGE_MATERIALS_CLIENT_ADD_FORM_VALUES:
            let clientMaterial = {...state.clientMaterial};
            clientMaterial[action.input] = action.value;
            if (action.input === 'isDavalchMaterial') {
                clientMaterial.price = 0;
                clientMaterial.currency = 2;
            }
            return {
                ...state,
                clientMaterial: clientMaterial
            };
        case CHANGE_MATERIALS_CLIENT_PART_ADD_FORM_VALUES:
            let clientMaterialPart = {...state.clientMaterialPart};
            clientMaterialPart[action.input] = action.value;
            return {
                ...state,
                clientMaterialPart: clientMaterialPart
            };
        case SET_PRODUCERS_DATA:
            return {
                ...state,
                producers: action.producers
            }
        case CLEAN_MATERIALS_FILTERS_VALUES:
            return {
                ...state,
                filters: initialState.filters
            };
        case CLEAN_MATERIALS_PART_ADD_FORM:
            return {
                ...state,
                clientMaterialPart: initialState.clientMaterialPart
            };
        case CLEAN_MATERIALS_ADD_FORM:
            return {
                ...state,
                clientMaterial: initialState.clientMaterial
            };
        case IS_STATE_FILTER_CNC_OVERRIDE: {
            return {
                ...state,
                filter_cnc_override: action.filter_cnc_override
            }
        }
        case MATERIAL_SET_REMAINS_STOCK_DT: {
            const updateMaterial = [...state.materials.data].map(m => {
                if (Number(m.goods_id) === Number(action.payload.id)) {
                    let copyGoods = [...m.goods_stock];
                    copyGoods.push(action.payload.dt);
                    m.goods_stock = [...copyGoods]
                }
                return m;
            });
            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: [...updateMaterial]
                }

            }
        }
        case MATERIAL_DELETE_REMAINS_STOCK_DT: {
            const updateMaterial = [...state.materials.data].map(m => {
                if (Number(m.goods_id) === Number(action.payload.goods_id)) {
                    const updateStock = m.goods_stock.filter(s => Number(s.goods_stock_id) != Number(action.payload.goods_stock_id));
                    m.goods_stock = [...updateStock];
                }
                return m;
            });
            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: [...updateMaterial]
                }

            }
        }
        case MATERIAL_DELETE_PLANE_STOCK_DT: {
            const updateMaterial = [...state.materials.data].map(m => {
                if (Number(m.goods_id) === Number(action.payload.goods_id)) {
                    const updateStock = m.goods_stock_plans.filter(s => Number(s.orders_goods_stock_rest_plan_id) != Number(action.payload.id))
                    m.goods_stock_plans = [...updateStock];
                }
                return m;
            });
            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: updateMaterial
                }

            }
        }
        case MATERIAL_UPDATE_PLANE_STOCK_DT: {
            const updateMaterial = [...state.materials.data].map(m => {
                if (Number(m.goods_id) === Number(action.payload.goods_id)) {
                    const updateStock = m.goods_stock_plans.map(s => {
                        if (Number(s.orders_goods_stock_rest_plan_id) === Number(action.payload.id)) {
                            s = {
                                ...s,
                                ...action.payload.dt
                            }
                        }
                        return s;


                    });

                    m.goods_stock_plans = [...updateStock]

                }
                return m;
            });

            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: updateMaterial
                }

            }
        }
        case MATERIAL_UPDATE_PRICE_DT: {
            const updateMaterials = [...state.materials.data].map(m => {
                if (Number(action.payload.goods) == Number(m.goods_id)) {
                    const labelDropCopy = m.drop_labels_user;
                    labelDropCopy.push(action.payload.price);
                    m.drop_labels_user = [...labelDropCopy];
                }
                return m;
            });
            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: updateMaterials
                }
            }
        }
        case MATERIAL_DELETE_PRICE_DT: {
            const updateMaterials = [...state.materials.data].map(m => {
                if (Number(action.payload.goods) == Number(m.goods_id)) {
                    const labelDropCopy = m.drop_labels_user.filter(d => Number(d.goods_firm_user_id) != Number(action.payload.goods_firm_user_id))
                    m.drop_labels_user = labelDropCopy;
                }
                return m;
            });
            return {
                ...state,

                materials: {
                    ...state.materials,
                    data: updateMaterials
                }
            }
        }
        default:
            return state
    }
}

export const setMaterialsProductPrice = (goods, price) => {
    return {
        type: MATERIAL_UPDATE_PRICE_DT,
        payload: {goods, price}
    }
}
export const setMaterialsDeleteProductPrice = (goods, goods_firm_user_id) => {
    return {
        type: MATERIAL_DELETE_PRICE_DT,
        payload: {goods, goods_firm_user_id}
    }
}
export const setMaterialsTreeDataAC = (tree) => {
    return {
        type: SET_MATERIALS_TREE_DATA,
        tree: tree
    }
}
export const setActiveMaterialsCategoryAC = (id, name) => {
    return {
        type: SET_ACTIVE_MATERIALS_CATEGORY,
        id: id,
        name: name
    }
}
export const setMaterialsDataAC = (count, data) => {
    return {
        type: SET_MATERIALS_DATA,
        count: count,
        data: data
    }
}
export const changeMaterialsSearchDataAC = (search) => {
    return {
        type: CHANGE_MATERIALS_SERACH_INPUT_DATA,
        search: search
    }
}
export const changeMaterialsFiltersValuesAC = (input, value) => {
    return {
        type: CHANGE_FILTERS_MATERIALS_VALUES_DATA,
        input: input,
        value: value
    }
}
export const changeClientMaterialValuesAC = (input, value) => {
    return {
        type: CHANGE_MATERIALS_CLIENT_ADD_FORM_VALUES,
        input: input,
        value: value
    }
}
export const changeClientMaterialPartValuesAC = (input, value) => {
    return {
        type: CHANGE_MATERIALS_CLIENT_PART_ADD_FORM_VALUES,
        input: input,
        value: value
    }
}
export const cleanMaterialsFiltersFormAC = () => {
    return {
        type: CLEAN_MATERIALS_FILTERS_VALUES,
    }
}
export const cleanMaterialsPartsFormDataAC = () => {
    return {
        type: CLEAN_MATERIALS_PART_ADD_FORM,
    }
}
export const cleanMaterialsFormDataAC = () => {
    return {
        type: CLEAN_MATERIALS_ADD_FORM,
    }
}
export const setProducersDataAC = (producers) => {
    return {
        type: SET_PRODUCERS_DATA,
        producers: producers
    }
}
export const getMaterialsTreeDataThunk = (isTreeSheet = null) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Получаем список номенклатуры...')));

    newApi.getMaterialsTree(isTreeSheet)
        .then(res => {
            if (res) {
                dispatch(setMaterialsTreeDataAC(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                if (newApi._errors) {
                    dispatch(changeAlertStatusAC(true, newApi._getErrors()));
                }
            }

        }).catch(e => {
        checkErrorsAPI('', 'api:/materialsn, M:P')

        // dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Произошел сбой при сохранении. Данные на сервере не сохранились")));
        dispatch(changeLoaderStatusAC(false));
    })
}
export const getMaterialsDataThunk = (category, filters, search, page) => (dispatch, getStore) => {
    const userId = getStore().order?.order?.client ?? getStore().auth.user.id;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Загружаем материалы по категории...')));
    newApi.getMaterials(category, filters, search, page, userId)
        .then(res => {
            if (res) {
                dispatch(setMaterialsDataAC(res.count, res.data));
                dispatch(changeLoaderStatusAC(false));
            } else {
                if (newApi.errors) {
                    dispatch(changeAlertStatusAC(true, newApi._getErrors()));
                }
            }
        }).catch(e => {
        checkErrorsAPI('', 'api:/materialsn, M:P, (getMaterialsDataThunk)')

        dispatch(changeLoaderStatusAC(false));
    })
}
export const getProducersThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Получаем список производителей...')));
    api.getProducers()
        .then(res => {
            if (res) {
                dispatch(setProducersDataAC(res));
                dispatch(changeLoaderStatusAC(false));
            } else {
                if (api.errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
        }).catch(e => {
        checkErrorsAPI('', 'api:/materials/producers/, M:G, (getProducersThunk)')

        dispatch(changeLoaderStatusAC(false));
    })
}


export const isStateFilterCncOverride = (value) => {
    return {
        type: IS_STATE_FILTER_CNC_OVERRIDE,
        filter_cnc_override: value
    }
}
export const setMaterialStockDT = ({id, dt}) => {
    return {
        type: MATERIAL_SET_REMAINS_STOCK_DT,
        payload: {id, dt}
    }
}
export const removeMaterialStockDT = ({goods_id, goods_stock_id}) => {
    return {
        type: MATERIAL_DELETE_REMAINS_STOCK_DT,
        payload: {
            goods_id,
            goods_stock_id
        }
    }
}
export const setMaterialPlaneStockDT = ({goods_id, id, dt}) => {
    return {
        type: MATERIAL_UPDATE_PLANE_STOCK_DT,
        payload: {goods_id, id, dt}
    }
}
export const removeMaterialPlaneStockDT = ({goods_id, id}) => {
    return {
        type: MATERIAL_DELETE_PLANE_STOCK_DT,
        payload: {
            goods_id,
            id
        }
    }
}
export const addStockMaterialClientThunk = (category, filters, search, page) => dispatch => {
    newApi.getMaterials(category, filters, search, page)
        .then(res => {
            if (res) {
                dispatch(addOrderMaterialAC(res.data[0]));
                // dispatch(changeStockMaterialClientModalValue('isOpen'))
                // dispatch(setMaterialsDataAC(res.count, res.data));
                // dispatch(changeLoaderStatusAC(false));
            } else {
                if (newApi.errors) {
                    dispatch(changeAlertStatusAC(true, newApi._getErrors()));
                }
            }
        }).catch(e => {
        checkErrorsAPI('', 'api:/materialsn, M:P, (getMaterials)')

        // dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Произошел сбой при сохранении. Данные на сервере не сохранились")));
        dispatch(changeLoaderStatusAC(false));
    })
}
export default MaterialsReducer;