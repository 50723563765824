import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC, checkErrorsAPI,} from "./CommonReducer";
import ApiService from "../../api/api";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {MODAL_TOGGLE_CREATE_PROJECT, MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE} from "../actions/modal-action";


const CHANGE_UPLOAD_PROJECT_MODAL_VALUE = 'CHANGE_UPLOAD_PROJECT_MODAL_VALUE';
const CHANGE_MATERIALS_MODAL_VALUE = 'CHANGE_MATERIALS_MODAL_VALUE';
const CHANGE_ADD_PART_MODAL_VALUE = 'CHANGE_ADD_PART_MODAL_VALUE';
const CHANGE_OPERATIONS_MODAL_VALUE = 'CHANGE_OPERATIONS_MODAL_VALUE';
const CHANGE_OPERATION_DATA = 'CHANGE_OPERATION_DATA';
const CHANGE_TEMPLATES_MODAL_DATA = 'CHANGE_TEMPLATES_MODAL_DATA';
const CHANGE_CHECK_PROJECT_MODAL_DATA = 'CHANGE_CHECK_PROJECT_MODAL_DATA';
const CHANGE_SHOPING_CARD_MODAL_DATA = 'CHANGE_SHOPING_CARD_MODAL_DATA';
const CHANGE_ADD_CLIENT_MATERIAL_MODAL_DATA = 'CHANGE_ADD_CLIENT_MATERIAL_MODAL_DATA';
const CHANGE_PLANE_OPERATIONS_MODAL_DATA = 'CHANGE_PLANE_OPERATIONS_MODAL_DATA';
const CHANGE_IMPORT_PROJECT_MODAL_DATA = 'CHANGE_IMPORT_PROJECT_MODAL_DATA';
const CHANGE_MATERIALS_PROJECT_MODAL_DATA = 'CHANGE_MATERIALS_PROJECT_MODAL_DATA';
const CHANGE_MATERIAL_INFO_MODAL_DATA = 'CHANGE_MATERIAL_INFO_MODAL_DATA';
const CHANGE_ORDER_CONFIRM_MODAL_DATA = 'CHANGE_ORDER_CONFIRM_MODAL_DATA';
const CHANGE_ORDER_DETAIL_MODAL_DATA = 'CHANGE_ORDER_DETAIL_MODAL_DATA';

const CHANGE_BATCH_MACHINING_MODAL_VALUE = 'CHANGE_BATCH_MACHINING_MODAL_VALUE';
const CHANGE_WARNING_MODAL_VALUE = "CHANGE_WARNING_MODAL_VALUE";


const CHANGE_MEMORY_DATA_ORDER_PROJECT_MODAL_VALUE = "CHANGE_MEMORY_DATA_ORDER_PROJECT_MODAL_VALUE";
const CHANGE_STOCK_MATERIAL_CLIENT = "CHANGE_STOCK_MATERIAL_CLIENT";
const CHANGE_NOT_WHOLE_REMNANTS_MODAL = "CHANGE_NOT_WHOLE_REMNANTS_MODAL";
const CHANGE_PRODUCT_PICTURE_MODAL = "CHANGE_PRODUCT_PICTURE_MODAL";
const FILE_MANAGER_MODAL_DT = "FILE_MANAGER_MODAL_DT";
const FILE_MANAGER_PHOTOS_MODAL_DT = "FILE_MANAGER_PHOTOS_MODAL_DT";
const IMPORT_OFFLINE_MODAL_DT = "IMPORT_OFFLINE_MODAL_DT";
const INSTRUCTIONS_MODAL_DT = 'INSTRUCTIONS_MODAL_DT';
const CELLS_CREATE_UPDATE_MODAL_DT = 'CELLS/CREATE_UPDATE_MODAL_DT';
const REMAINS_CREATE_UPDATE_MODAL_DT = 'REMAINS/CREATE_UPDATE_MODAL_DT';
const PLAN_UPDATE_MODAL_DT = 'PLANE/UPDATE_MODAL_DT';
const CUSTOMER_BALANCE_UPDATE_MODAL_DT = 'CUSTOMER_BALANCE/UPDATE_MODAL_DT';
const MATERIAL_PRODUCT_PRICE_CU_DT = 'MATERIAL_PRODUCT/MODAL_PRICE_CU_DT';
const REQUEST_INVOICE_MODAL_DT = 'PAY_MODAL/REQUEST_INVOICE_DT';

const VIDEO_PRESENTATION_BOX = 'VIDEO_PRESENTATION_BOX';

const COMBINE_MATERIAL_MODAL_CU = 'MODAL/COMBINE_MATERIAL_CU';
const TEMPLATE_PAZ_DETAIL_MODAL_CU = 'MODAL/TEMPLATE_PAZ_DETAIL_CU';
const ADD_CONNECTION_BAND_MODAL = 'MODAL/ADD_CONNECTION_BAND';
const LABELS_PRINT_GRID = 'MODAL/LABELS_PRINT_GRID';
const POSTFORMING_MODAL = 'MODAL/POSTFORMING';
const PACKED_MODAL = 'MODAL/PACKED';
const api = new ApiService();

let initialState = {
    order_part_goods_service: {
        isOpen: false,
        part: null,
        sizePart: null,
        product_count: 1
    },
    templatePaz: {
        isOpen: false,
        part: null
    },
    combineMaterial: {
        isOpen: false
    },
    uploadProject: {
        isOpen: false
    },
    materials: {
        isOpen: false
    },
    addPart: {
        isOpen: false
    },
    operations: {
        isOpen: false,
        part: null,
        aside: null
    },
    templates: {
        isOpen: false,
        category: null,
        search: null
    },
    checkProject: {
        isOpen: false
    },
    shopingCard: {
        isOpen: false
    },
    addClientMaterial: {
        isOpen: false
    },
    planeOperations: {
        isOpen: false,
        search: null
    },
    importProject: {
        isOpen: false,
        data: null,
        name_part_put: null,
        arr: false,
        require_choose_material: false
    },
    materialInfo: {
        isOpen: false,
        material_id: null
    },
    confirmOrder: {
        isOpen: false,
    },
    orderDetail: {
        isOpen: false,
        detail: null,
        main: null
    },
    materialsProject: {
        isOpen: false
    },
    batchMachiningHoles: {
        isOpen: false,
        processing_current_material: null
    },
    warningModal: {
        isOpen: false,
        additional_data: null,
        onSuccessful: null,
        message: null,
        title: null,
    },
    memoryDateOrderModal: {
        isOpen: false
    },
    stockMaterialClient: {
        isOpen: false,
        stock: []
    },
    notWholeRemnants: {
        isOpen: false,
        stock: []
    },
    productPicture: {
        isOpen: false,
        url: ""
    },
    fileManager: {
        isOpen: false,
        listFiles: [],
        addedFiles: {},
        isAddedFiles: false
    },
    fileManagerPhotos: {
        isOpen: false,
        article_id: null
    },
    importOffline: {
        isOpen: false,
        nameCompany: {},
        isSuccessful: false
    },
    instrumentsModal: {
        bore: false,
        paz: false,
        mill: false
    },
    cellModal: {
        isOpen: false,
        info: {
            isOpen: false,
            title: null
        }
    },
    remainsModal: {
        isOpen: false,
        formAdd: {
            isOpen: false,
            goods: null,
            order: null,
            name: null
        },
        listView: {
            isOpen: false,
            goods: null,

        },
        fullInfo: {
            isOpen: false,
            title: null
        },
        create: {
            isOpen: false,
            materials: [],
            order: null
        },
        listViewOrder: {
            isOpen: false,
            idOrder: null
        }
    },
    planModal: {
        edit: {
            isOpen: false,
        },
        create: {
            isOpen: false,
            materials: [],
        },
        info: {
            isOpen: false

        },
        materialList: {
            isOpen: false,
            planeStock: [],
            goods_id: null
        },
        materialEdit: {},
        planeListOrder: {
            isOpen: false,
            idOrder: null
        }
    },
    customerBalanceModal: {
        listView: {
            isOpen: false,
            stock: []
        }
    },
    materialProductPrice: {
        isOpen: false,
        price: [],
        title: '',
        part: null,
        goods: null
    },
    payModal: {
        requestInvoice: {
            isOpen: false,
            isSuccessful: false
        }
    },
    videoPresentation: {
        isOpen: false
    },
    connectionBand: {
        isOpen: false,
        connectionBands: [],

    },
    labelsPrint: {
        isOpen: false,
        action: '',
        grid: 1
    },
    postforming: {
        isOpen: false, list: null, materialId: null
    },
    packedModal: {
        isOpen: false
    },
    createProject: {
        isOpen: false,
        user_id: null, firm: null
    }
};

const ModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE: {
            return {
                ...state,
                order_part_goods_service: {
                    ...action.payload
                }
            }
        }
        case MODAL_TOGGLE_CREATE_PROJECT: {
            return {
                ...state,
                createProject: {
                    ...state.createProject,
                    ...action.payload
                }
            }
        }
        case PACKED_MODAL: {
            return {
                ...state,
                packedModal: {
                    ...action.payload
                }
            }
        }
        case POSTFORMING_MODAL: {
            return {
                ...state,
                postforming: {
                    ...action.payload
                }
            }
        }

        case LABELS_PRINT_GRID: {
            return {
                ...state,
                labelsPrint: {
                    ...state?.labelsPrint,
                    ...action.payload
                }
            }
        }
        case ADD_CONNECTION_BAND_MODAL: {
            return {
                ...state,
                connectionBand: {...action?.payload}
            }
        }
        case TEMPLATE_PAZ_DETAIL_MODAL_CU: {
            return {
                ...state,
                templatePaz: {
                    ...action?.payload
                }
            }
        }
        case COMBINE_MATERIAL_MODAL_CU: {
            return {
                ...state,
                combineMaterial: {
                    ...state?.combineMaterial,
                    [action?.payload?.key]: action?.payload?.value
                }
            }
        }
        case CHANGE_UPLOAD_PROJECT_MODAL_VALUE:
            let upload_project = {...state.uploadProject};
            upload_project[action.input] = action.value;
            return {
                ...state,
                uploadProject: upload_project
            };
        case CHANGE_MATERIALS_MODAL_VALUE:
            let materials = {...state.materials};
            materials[action.input] = action.value;
            return {
                ...state,
                materials: materials
            };
        case CHANGE_ADD_PART_MODAL_VALUE:
            let add_part = {...state.addPart};
            add_part[action.input] = action.value;
            return {
                ...state,
                addPart: add_part
            };
        case CHANGE_TEMPLATES_MODAL_DATA:
            let templates = {...state.templates};
            templates[action.input] = action.value;
            return {
                ...state,
                templates: templates
            };
        case CHANGE_ORDER_DETAIL_MODAL_DATA:
            let orderDetail = {...state.orderDetail};
            orderDetail[action.input] = action.value;
            return {
                ...state,
                orderDetail: orderDetail
            };
        case CHANGE_IMPORT_PROJECT_MODAL_DATA:
            let importProject = {...state.importProject};
            importProject[action.input] = action.value;
            return {
                ...state,
                importProject: importProject
            };
        case CHANGE_OPERATIONS_MODAL_VALUE:
            let operations = {...state.operations};
            operations[action.input] = action.value;
            return {
                ...state,
                operations: operations
            };
        case CHANGE_MATERIAL_INFO_MODAL_DATA:
            let materialInfo = {...state.materialInfo};
            materialInfo[action.input] = action.value;
            return {
                ...state,
                materialInfo: materialInfo
            };
        case CHANGE_CHECK_PROJECT_MODAL_DATA:
            let check_project = {...state.check_project};
            check_project[action.input] = action.value;
            return {
                ...state,
                checkProject: check_project
            };
        case CHANGE_SHOPING_CARD_MODAL_DATA:
            let shoping_card = {...state.shopingCard};
            shoping_card[action.input] = action.value;
            return {
                ...state,
                shopingCard: shoping_card
            };
        case CHANGE_ORDER_CONFIRM_MODAL_DATA:
            let confirmOrder = {...state.confirmOrder};
            confirmOrder[action.input] = action.value;
            return {
                ...state,
                confirmOrder: confirmOrder
            };
        case CHANGE_ADD_CLIENT_MATERIAL_MODAL_DATA:
            let add_client_material = {...state.addClientMaterial};
            add_client_material[action.input] = action.value;
            return {
                ...state,
                addClientMaterial: add_client_material
            };
        case CHANGE_PLANE_OPERATIONS_MODAL_DATA:
            let planeOperations = {...state.planeOperations};
            planeOperations[action.input] = action.value;
            return {
                ...state,
                planeOperations: planeOperations
            };
        case CHANGE_MATERIALS_PROJECT_MODAL_DATA:
            let materialsProject = {...state.materialsProject};
            materialsProject[action.input] = action.value;
            return {
                ...state,
                materialsProject: materialsProject
            };
        case CHANGE_OPERATION_DATA:
            return {
                ...state,
                operations: {
                    ...state.operations,
                    aside: 'history'
                }
            };
        case CHANGE_BATCH_MACHINING_MODAL_VALUE:
            // let batch_machining = {...state.batchMachiningHoles};
            // batch_machining[action.input] = action.value;
            return {
                ...state,
                batchMachiningHoles: {
                    ...state.batchMachiningHoles, ...action.payload
                }
            };
        case CHANGE_WARNING_MODAL_VALUE: {
            return {
                ...state,
                warningModal: {
                    ...action.payload
                }
            }
        }
        case CHANGE_MEMORY_DATA_ORDER_PROJECT_MODAL_VALUE: {
            let memory_dt = {...state.memoryDateOrderModal}

            memory_dt[action.input] = action.value;
            return {
                ...state,
                memoryDateOrderModal: memory_dt
            }
        }
        case CHANGE_STOCK_MATERIAL_CLIENT: {
            let stock_client = {...state.stockMaterialClient};
            stock_client[action.input] = action.value;
            return {
                ...state,
                stockMaterialClient: stock_client
            }
        }
        case CHANGE_NOT_WHOLE_REMNANTS_MODAL: {
            let values = {...state.notWholeRemnants};
            values[action.input] = action.value;
            return {
                ...state,
                notWholeRemnants: values
            }
        }
        case CHANGE_PRODUCT_PICTURE_MODAL: {
            let values = {...state.productPicture};
            values[action.input] = action.value;
            return {
                ...state,
                productPicture: values
            }
        }
        case FILE_MANAGER_MODAL_DT: {
            return {
                ...state,
                fileManager: {
                    ...state.fileManager,
                    ...action.payload
                }
            }
        }
        case FILE_MANAGER_PHOTOS_MODAL_DT: {
            return {
                ...state,
                fileManagerPhotos: {
                    ...state.fileManagerPhotos,
                    ...action.payload
                }
            }
        }
        case IMPORT_OFFLINE_MODAL_DT: {
            return {
                ...state,
                importOffline: {
                    ...state.importOffline,
                    ...action.payload
                }
            }
        }
        case INSTRUCTIONS_MODAL_DT: {
            return {
                ...state,
                instrumentsModal: {
                    ...state.instrumentsModal,
                    [action.key]: action.dt
                }
            }
        }
        case CELLS_CREATE_UPDATE_MODAL_DT: {
            return {
                ...state,
                cellModal: {
                    ...state.cellModal,
                    [action.key]: action.dt
                }
            }
        }
        case REMAINS_CREATE_UPDATE_MODAL_DT: {
            return {
                ...state,
                remainsModal: {
                    ...state.remainsModal,
                    [action.key]: action.dt
                }
            }
        }
        case PLAN_UPDATE_MODAL_DT: {
            return {
                ...state,
                planModal: {
                    ...state.planModal,
                    [action.key]: action.dt
                }
            }
        }
        case CUSTOMER_BALANCE_UPDATE_MODAL_DT: {
            return {
                ...state,
                customerBalanceModal: {
                    ...state.customerBalanceModal,
                    [action.key]: action.dt
                }
            }
        }
        case REQUEST_INVOICE_MODAL_DT: {
            return {
                ...state,
                payModal: {
                    ...state.payModal,
                    [action.key]: action.dt
                }
            }
        }
        case MATERIAL_PRODUCT_PRICE_CU_DT: {
            return {
                ...state,
                materialProductPrice: {
                    ...state.materialProductPrice,
                    ...action.dt
                }
            }
        }
        case VIDEO_PRESENTATION_BOX: {
            return {
                ...state,
                videoPresentation: {
                    isOpen: action.payload
                }
            }
        }
        default:
            return state
    }
}
export const onChangeLabelsPrintGirdModal = (data) => ({
    type: LABELS_PRINT_GRID,
    payload: data
})
export const onTogglePostformingModal = ({isOpen, list = null, materialId = null}) => ({
    type: POSTFORMING_MODAL,
    payload: {isOpen, list, materialId}
})
export const onTogglePackedModal = ({isOpen}) => ({
    type: PACKED_MODAL,
    payload: {isOpen}
})

export const onChangeConnectionBand = ({isOpen, connectionBands}) => ({
    type: ADD_CONNECTION_BAND_MODAL,
    payload: {isOpen, connectionBands}
})

export const onChangeTemplatePazModal = (data) => {
    return {
        type: TEMPLATE_PAZ_DETAIL_MODAL_CU,
        payload: data
    }
}
export const onChangeCombineMaterialModal = ({key, value}) => {
    return {
        type: COMBINE_MATERIAL_MODAL_CU,
        payload: {key, value}
    }
}
export const onVideoPresentationModal = (isOpen) => {
    return {
        type: VIDEO_PRESENTATION_BOX,
        payload: isOpen
    }
}

export const onPayRequestInvoiceModal = (data) => {
    return {
        type: REQUEST_INVOICE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onOpenInstructionModal = (data) => {
    return {
        type: INSTRUCTIONS_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onToggleCellModal = (data) => {
    return {
        type: CELLS_CREATE_UPDATE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onToggleRemainModal = (data) => {
    return {
        type: REMAINS_CREATE_UPDATE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onTogglePlanModal = (data) => {
    return {
        type: PLAN_UPDATE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onToggleCustomerBalanceModal = (data) => {
    return {
        type: CUSTOMER_BALANCE_UPDATE_MODAL_DT,
        key: data.key,
        dt: data.dt
    }
}
export const onToggleMaterialProductPriceModal = (data) => {
    return {
        type: MATERIAL_PRODUCT_PRICE_CU_DT,
        key: data.key,
        dt: data.dt
    }
}

export const changeUploadProjectModalValue = (input, value) => {
    return {
        type: CHANGE_UPLOAD_PROJECT_MODAL_VALUE,
        input: input,
        value: value
    }
}
export const changeMaterialsModalValue = (input, value) => {
    return {
        type: CHANGE_MATERIALS_MODAL_VALUE,
        input: input,
        value: value
    }
}
export const changeAddPartModalValue = (input, value) => {
    return {
        type: CHANGE_ADD_PART_MODAL_VALUE,
        input: input,
        value: value
    }
}
export const changeOperationsModalValue = (input, value) => {
    return {
        type: CHANGE_OPERATIONS_MODAL_VALUE,
        input: input,
        value: value
    }
}
export const changeTemplatesModalValue = (input, value) => {
    return {
        type: CHANGE_TEMPLATES_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeCheckProjectModalValue = (input, value) => {
    return {
        type: CHANGE_CHECK_PROJECT_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeShopingCardModalValue = (input, value) => {
    return {
        type: CHANGE_SHOPING_CARD_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeMaterialInfoModalValue = (input, value) => {
    return {
        type: CHANGE_MATERIAL_INFO_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeOrderDetailModalValue = (input, value) => {
    return {
        type: CHANGE_ORDER_DETAIL_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeAddClientMaterialModalValue = (input, value) => {
    return {
        type: CHANGE_ADD_CLIENT_MATERIAL_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeImportProjectModalValue = (input, value) => {
    return {
        type: CHANGE_IMPORT_PROJECT_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeConfirmOrderModalValue = (input, value) => {
    return {
        type: CHANGE_ORDER_CONFIRM_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changePlaneOparetionsModalValue = (input, value) => {
    return {
        type: CHANGE_PLANE_OPERATIONS_MODAL_DATA,
        input: input,
        value: value
    }
}
export const changeMaterialsProjectModalValue = (input, value) => {
    return {
        type: CHANGE_MATERIALS_PROJECT_MODAL_DATA,
        input: input,
        value: value
    }
}

export const changeBatchMachiningModalValue = (payload) => {
// export const changeBatchMachiningModalValue = (input, value) => {
    return {
        type: CHANGE_BATCH_MACHINING_MODAL_VALUE,
        payload: payload
    }
}
// additional_data: null,
//     onSuccessful: null,
//     message: null,
//     title: null,
export const changeWarningModalValue = ({
                                            isOpen = false,
                                            message = null,
                                            title = null,
                                            onSuccessful = null,
                                            additional_data = null
                                        }) => {
    return {
        type: CHANGE_WARNING_MODAL_VALUE,
        payload: {
            isOpen: isOpen, message: message, title: title, onSuccessful: onSuccessful, additional_data: additional_data
        }
    }
}
export const changeMemoryDTOrderProjectModalValue = (input, value) => {
    return {
        type: CHANGE_MEMORY_DATA_ORDER_PROJECT_MODAL_VALUE,
        input: input,
        value: value
    }
}
export const changeStockMaterialClientModalValue = (input, value) => {
    return {
        type: CHANGE_STOCK_MATERIAL_CLIENT,
        input: input,
        value: value
    }
}
export const changeNotWholeRemnantsModalValue = (input, value) => {
    return {
        type: CHANGE_NOT_WHOLE_REMNANTS_MODAL,
        input: input,
        value: value
    }
}
export const onChangeValueProductPicture = (input, value) => {
    return {
        type: CHANGE_PRODUCT_PICTURE_MODAL,
        input: input,
        value: value
    }
}
export const setStockMaterialClient = (clientId) => (dispatch) => {
    api.getStockMaterialClientData({
        client: clientId,
        get_stock: 1,
        firm: localStorage.getItem("ifurn_service_main_user_firm")
    })
        .then(res => {
            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
            } else {
                dispatch(changeStockMaterialClientModalValue('stock', res.stock));
                dispatch(changeStockMaterialClientModalValue('isOpen', true));
            }
        })
        .catch(e => {
            dispatch(changeAlertStatusAC(true, getTranslateMessageAPI("Произошел сбой при сохранении. Данные на" +
                " сервере не сохранились")));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));
        })
}
export const setNotWholeRemnantsMaterialClient = () => (dispatch) => {
    api.getStockMaterialClientData({
        stock_all: 1,
        get_stock: 1,
        firm: localStorage.getItem("ifurn_service_main_user_firm")
    })
        .then(res => {
            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
            } else {
                dispatch(changeNotWholeRemnantsModalValue('stock', res.stock));
                dispatch(changeNotWholeRemnantsModalValue('isOpen', true));
            }
        })
        .catch(e => {
            checkErrorsAPI('', 'api: /clients, M:P (setNotWholeRemnantsMaterialClient)')
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));
        })
}

export const getOrderDetailThunk = (order) => (dispatch, getState) => {

    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC('Получаем данные по заказу...'));
    api.getOrderDetail(order.id)
        .then(res => {
            if (res) {
                dispatch(changeOrderDetailModalValue('detail', res));
                dispatch(changeOrderDetailModalValue('main', order));
                dispatch(changeOrderDetailModalValue('isOpen', true));
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        }).catch(e => {
        checkErrorsAPI('', 'api: /orders/id, M:G (getOrderDetailThunk)')
        dispatch(changeLoaderStatusAC(false));
    })
}


export const onChangeFileManagerModalDT = (payload) => {
    return {
        type: FILE_MANAGER_MODAL_DT,
        payload
    }
}
export const onChangeFileManagerPhotosModalDT = (payload) => {
    return {
        type: FILE_MANAGER_PHOTOS_MODAL_DT,
        payload
    }
}

export const setFileManagerDT = ({isOpen, idProject}) => async (dispatch) => {
    try {
        const response = await api._getFilesListManager(idProject);
        if (!response.hasOwnProperty('error_api')) {
            dispatch(onChangeFileManagerModalDT({
                isOpen: true,
                listFiles: Array.isArray(response) ? response : [response]
            }))
        } else if (response.hasOwnProperty('error_api') && response['error_api']['api_error']) {
            dispatch(changeAlertStatusAC(true, response['error_api']['api_error'][0].error));

        }
    } catch (e) {
        checkErrorsAPI('', 'api:/files/{idProject}/, M:G (setFileManagerDT)')
        dispatch(changeLoaderStatusAC(false));
    }
}

export const deleteItemFileManagerDT = (url) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {
        const response = await api._deleteFilesListManager(url);
        const listFiles = getState().modal.fileManager.listFiles;
        const updateResponse = listFiles.filter(l => l.link_delete != url);
        if (!response.hasOwnProperty('error_api') && (response.delete == 'ok')) {
            dispatch(onChangeFileManagerModalDT({listFiles: updateResponse}))
        } else if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        checkErrorsAPI('', 'api:' + url + 'M:G')
        dispatch(changeLoaderStatusAC(false));
    }

}

export const importFileManageProjectsThunk = (files) => (dispatch, getState) => {
    const orderCode = getState().order.order.code;
    const isManager = getState().auth.user.isManager;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Отправляем проекты на проверку...')));
    api.importProject(files, 'files/', [{name: 'action', value: 'add_file'}, {
        name: 'order',
        value: orderCode
    }, {name: 'isManager', value: isManager}])
        .then(response => {
            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
                return
            } else if (response.hasOwnProperty('res') && (response.res == 'ok')) {
                dispatch(onChangeFileManagerModalDT({listFiles: response.dir, isAddedFiles: true}))
            }
        }).catch(e => {
        checkErrorsAPI('', 'api:files/, M:G, (add_file)')
        dispatch(changeLoaderStatusAC(false));
    })
    dispatch(changeLoaderStatusAC(false));
}


export const onImportOfflineCompany = ({files, username, phone, email, count}) => (dispatch, getState) => {
    const orderCode = getState().order.order.code;
    const isManager = getState().auth.user.isManager;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Отправляем проекты на проверку...')));
    api.importProject(files, 'files/', [
        {name: 'action', value: 'add_file'},
        {
            name: 'order',
            value: orderCode
        },
        {name: 'isManager', value: isManager},
        {name: 'phone', value: phone},
        {name: 'username', value: username},
        {name: 'email', value: email},
        {name: 'count', value: count}
    ])
        .then(response => {
            if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
                return
            } else if (response.hasOwnProperty('res') && (response.res == 'ok')) {
                dispatch(onChangeImportOfflineModal({isSuccessful: true}));
            }
        }).catch(e => {
        checkErrorsAPI('', 'api:files/ M:G (add-file) ')
        dispatch(changeLoaderStatusAC(false));
    })
    dispatch(changeLoaderStatusAC(false));
}

export const onChangeImportOfflineModal = (payload) => {
    return {
        type: IMPORT_OFFLINE_MODAL_DT,
        payload
    }
}

export const getCompanyNameImportOffline = ({isOpen, key}) => async dispatch => {
    const encodedString = btoa(`502141${key}`);
    const response = await api.getRegisterFirmName(encodedString);


    try {
        dispatch(onChangeImportOfflineModal({
            isOpen,
            nameCompany: response.hasOwnProperty('data') ? response.data : {}
        }))

    } catch (e) {
    }
}

export const onSendPayRequestInvoice = (text) => async dispatch => {
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._onSendPayRequestInvoice(text);

        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        } else {
            dispatch(onPayRequestInvoiceModal({key: 'requestInvoice', dt: {isOpen: true, isSuccessful: true}}))
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        checkErrorsAPI('', 'api: mail, M:G, action: send_form_for_payment ')
        dispatch(changeLoaderStatusAC(false));
    }
}


export const fetchingPostformingTemplate = ({materialId = null}) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));

    try {

        const data = await api._getPostformingTemplate(materialId);
        if (Array.isArray(data)) {
            dispatch(onTogglePostformingModal({isOpen: true, list: data, materialId: materialId}));

        } else {
            dispatch(changeAlertStatusAC(true, JSON.stringify(data)));
        }
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        console.error(e.message)
        dispatch(changeLoaderStatusAC(false));
    }
}
export default ModalReducer;


//selectors
export const getCombineModalDTSelector = state => state?.modal?.combineMaterial || initialState?.combineMaterial
export const getConnectionBandSelector = state => state?.modal?.connectionBand || initialState?.connectionBand

