import React, {useState} from "react";
import Setting from "../../../Icons/Actions/Settings";
import "./Settings.css";
import Checbox from "../../StyledChecbox";
import {useDispatch, useSelector} from "react-redux";
import {getAllPartSizesThunk} from "../../../store/reducers/OrderReducer";
import {useDetectClickOutside} from "react-detect-click-outside";
import {setSubstrateBody} from "../../../helpers/helpers";
import useSettingsCookies from "../../../HOOK/useSettingsCookies";
import {useLang} from "../../../context/langProvider";
import {getProductionPazTemplateOffInInterface} from "../../../selectors/production-selectors";
import IconQuestion from "../../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../../store/constats/help_article_constants";

const Settings = ({handlerHelpArticle}) => {

    const [openSettings, setOpenSettings] = useState(false);
    const {getT} = useLang();

    const [_, settings, onChangeCookieSetting] = useSettingsCookies();
    const toggleOpenSettings = (val) => {
        setSubstrateBody(val, 'settings')
        setOpenSettings(val)
    };

    const dispatch = useDispatch();

    const sizeType = useSelector((state) => state.order.order.partSizesType);

    const changeSizeType = (size_type) => dispatch(getAllPartSizesThunk(size_type));

    const refSettings = useDetectClickOutside({onTriggered: () => toggleOpenSettings(false)});

    return (
        <div className="settings" ref={refSettings}>
            <div className="settings-helper-article">

                <Setting onClick={() => toggleOpenSettings(!openSettings)}/>
                <IconQuestion onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.setting_table)}
                              style={{marginLeft: '6px'}}/>
            </div>

            {openSettings && (
                <div className="settings-container">
                    <div className="settings-content">
                        <h3 className="settings-title">{getT('Настройки таблицы')}</h3>
                        <div className="setting-item">
                            <p>{getT('Название')}:</p>
                            <Checbox onClick={() => (onChangeCookieSetting({key: 'names'}))} checked={settings?.names}
                                     name="names"/>
                        </div>
                        <div className="setting-item">
                            <p>{getT('Материал')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'material'}))}
                                checked={settings?.material}
                                name="material"
                            />
                        </div>
                        {/*<div className="setting-item">*/}
                        {/*    <p>{getT('Материал и изделие')}:</p>*/}
                        {/*    <Checbox*/}
                        {/*        onClick={() => (onChangeCookieSetting({key: 'material_column'}))}*/}
                        {/*        checked={settings?.material_column}*/}
                        {/*        name="material_column"*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div className="setting-item">
                            <p>{getT('Кромкование')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'band_column'}))}
                                checked={settings?.band_column}
                                name="band_column"
                            />
                        </div>
                        <div className="setting-item">
                            <p>{getT('Чертеж')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'picture'}))}
                                checked={settings?.picture}

                                name="picture"
                            />
                        </div>
                        <div className="setting-item">
                            <p>{getT('Быстрый режим кромки')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'band'}))}
                                checked={settings?.band}
                                name="band"/>
                        </div>

                        <div className="setting-item">
                            <p>{getT('Корректировка ЧПУ')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'cnc_override'}))}
                                checked={settings?.cnc_override}
                                name="cnc_override"/>
                        </div>
                        <div className="setting-item">
                            <p>{getT('Обработок торцов в кратком режиме')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'edge_edit_in_brief_mode'}))}
                                checked={settings?.edge_edit_in_brief_mode}
                                name="edge_edit_in_brief_mode"/>
                        </div>
                        <div className="setting-item">
                            <p>{getT('Материал и изделие в кратком виде')}:</p>
                            <Checbox
                                onClick={() => (onChangeCookieSetting({key: 'material_product_in_small_view'}))}
                                checked={settings?.material_product_in_small_view}
                                name="material_product_in_small_view"/>
                        </div>
                        {true &&
                            // {paz_template_off_interface &&
                            <div className="setting-item">
                                <p>{getT('Шаблон пазования')}:</p>
                                <Checbox
                                    onClick={() => (onChangeCookieSetting({key: 'paz_template'}))}
                                    checked={settings?.paz_template}
                                    name="paz_template"/>
                            </div>}
                        <div className="setting-item">
                            <div className="form-field">
                                <select
                                    className={'form-input'}
                                    value={sizeType}
                                    defaultValue={sizeType}
                                    onChange={(event) => changeSizeType(event.target.value)}
                                >
                                    <option value={'saw'}>{getT('Перед прифуговкой без кромки')} </option>
                                    <option value={'gabarit'}>{getT('Деталь с кромкой')}</option>
                                    <option value={'w_pre_joint'}>{getT('После прифуговки без кромки')}</option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Settings;

