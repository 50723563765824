import { tools } from './tools.js';
import { translateLib } from './translate.js';
import { troughs } from './troughs.js';

export const pazes = {
  verticalPaz(
    d, d1, z, side, sideFrom, typePaz, w, wPaz, dSideStart, dSideEnd, partX,
    partY, partX1, partY1, partZ, pazId, partSizeType, params = {}
  ) {
    let x, y
    let defaultParams = {
      'stroke-width': '1px'
    };
    params = { ...defaultParams, ...params };

    if (partSizeType === 'gabarit') {
      d = d1;
    }

    let colors = {
      f: [tools.pazBlind, tools.pazThrough],
      bb: [tools.pazBackBlind, tools.pazBackThrough],
    };
    let sideFromCoords = {
      l: [d, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
      r: [partX - d - wPaz, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
      b: [dSideStart, partY - d - wPaz, partX - dSideEnd - dSideStart, wPaz],
      t: [dSideStart, d, partX - dSideEnd - dSideStart, wPaz],
    };
    let sideFromCoords1 = {
      l: [d1, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
      r: [partX1 - d1 - wPaz, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
      b: [dSideStart, partY1 - d1 - wPaz, partX1 - dSideEnd - dSideStart, wPaz],
      t: [dSideStart, d1, partX1 - dSideEnd - dSideStart, wPaz],
    };
    let [colorBlind, colorThrough] = colors[side];
    let fill = z < partZ ? colorBlind : colorThrough;
    let h;
    [x, y, w, h] = sideFromCoords[sideFrom];
    let [x1, y1, w1, h1] = sideFromCoords1[sideFrom];

    return [
      tools.rect(x, y, w, h, {
        fill: fill, hover_show: pazId, ...params
      }),
      [x, y, x1, y1, z, w, h, partX, partY, partX1, partY1, partZ, sideFrom]
    ];
  },
  horizontalPaz(
    d, z, side, sideFrom, typePaz, w, wPaz, dSideStart, dSideEnd, partX,
    partY, partZ
  ) {
    let x, y;
    // paz on left, top, right, bottom sides
    let sideCoords = {
      l: [0, dSideEnd, z, partY - dSideStart - dSideEnd],
      r: [partX - z, dSideEnd, z, partY - dSideStart - dSideEnd],
      b: [dSideStart, partY - z, partX - dSideEnd - dSideStart, z],
      t: [dSideStart, partZ - d - wPaz, partX - dSideEnd - dSideStart, wPaz],
    }

    let fill = tools.pazBlind;
    let h;
    [x, y, w, h] = sideCoords[side];
    return tools.rect(x, y, w, h, { fill: fill });
  },
  onSidePaz(
    d, d1, z, side, sideFrom, typePaz, w, wPaz, dSideStart, dSideEnd, partX,
    partY, partX1, partY1, partZ, partSide, pazId, partSizeType, params = {}
  ) {
    let defaultParams = {
      'stroke-width': '1px'
    };
    params = { ...defaultParams, params };

    if (partSizeType === 'gabarit') {
      d = d1;
    }

    // pazSide => pazSideFrom => partSide
    let frontPazesTracesCoords = {
      f: {
        l: {
          t: [d, partZ - z, wPaz, z],
          b: [d, 0, wPaz, z],
        },
        r: {
          t: [partX - d - wPaz, partZ - z, wPaz, z],
          b: [partX - d - wPaz, 0, wPaz, z],
        },
        t: {
          l: [partZ - z, d, z, wPaz],
          r: [0, d, z, wPaz],
        },
        b: {
          l: [partZ - z, partY - d - wPaz, z, wPaz],
          r: [0, partY - d - wPaz, z, wPaz],
        },
      },
      bb: {
        l: {
          b: [d, partZ - z, wPaz, z],
          t: [d, 0, wPaz, z],
        },
        r: {
          b: [partX - d - wPaz, partZ - z, wPaz, z],
          t: [partX - d - wPaz, 0, wPaz, z],
        },
        t: {
          r: [partZ - z, d, z, wPaz],
          l: [0, d, z, wPaz],
        },
        b: {
          r: [partZ - z, partY - d - wPaz, z, wPaz],
          l: [0, partY - d - wPaz, z, wPaz],
        }
      }
    };
    // side: {
    //  sideFrom: [...coords]
    // }
    let coords = {
      l: {
        l: [d, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
        r: [partZ - d - wPaz, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
      },
      r: {
        l: [d, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
        r: [partZ - d - wPaz, dSideEnd, wPaz, partY - dSideStart - dSideEnd],
      },
      t: {
        b: [dSideStart, partZ - d - wPaz, partX - dSideEnd - dSideStart, wPaz],
        t: [dSideStart, d, partX - dSideEnd - dSideStart, wPaz],
      },
      b: {
        t: [dSideStart, d, partX - dSideEnd - dSideStart, wPaz],
        b: [dSideStart, partZ - d - wPaz, partX - dSideEnd - dSideStart, wPaz],
      }
    };
    let coords1 = {
      l: {
        l: [d1, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
        r: [partZ - d1 - wPaz, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
      },
      r: {
        l: [d1, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
        r: [partZ - d1 - wPaz, dSideEnd, wPaz, partY1 - dSideStart - dSideEnd],
      },
      t: {
        b: [dSideStart, partZ - d1 - wPaz, partX1 - dSideEnd - dSideStart, wPaz],
        t: [dSideStart, d1, partX1 - dSideEnd - dSideStart, wPaz],
      },
      b: {
        t: [dSideStart, d1, partX1 - dSideEnd - dSideStart, wPaz],
        b: [dSideStart, partZ - d1 - wPaz, partX1 - dSideEnd - dSideStart, wPaz],
      }
    };

    let forHover = null;
    let fill, x, y, h;

    if (Object.keys(coords).includes(side)) {
      [x, y, w, h] = coords[side][sideFrom];
      let [x1, y1, w1, h1] = coords1[side][sideFrom];
      forHover = [
        x, y, x1, y1, z, w, h, partX, partY, partX1, partY1, partZ, sideFrom
      ];
      fill = tools.pazBlind;
    } else {
      let onPartCoords = frontPazesTracesCoords[side][sideFrom][partSide];

      if (onPartCoords === undefined) {
        return ['', null];
      }

      [x, y, w, h] = onPartCoords;
      fill = side === 'f' ? tools.pazBlind : tools.pazBackBlind;
    }

    return [
      tools.rect(
        x, y, w, h, {
        fill: fill,
        hover_show: pazId,
        ...params
      }
      ),
      forHover
    ];
  },
  pazMeasurementCoords(
    x, y, x1, y1, z, w, h, partX, partY, partX1, partY1, partZ, sideFrom, side,
    parentTranslate, sideTranslates, measurementOffset, pazId
  ) {
    let coords = troughs.troughMeasurementCoords(
      x, y, x1, y1, w, h, partX, partY, partX1, partY1, partZ, side,
      parentTranslate, sideTranslates, measurementOffset, pazId
    ).slice(0, -1);
    return coords;
  },
  pazInfoHighlight(
    x, y, x1, y1, z, w, h, partX, partY, partX1, partY1, partZ, sideFrom,
    parentTranslate, sideTranslates, pazId, pazTable, operationId
  ) {
    let [lx, ly] = sideTranslates.left;
    let [tx, ty] = sideTranslates.top;
    let [rx, ry] = sideTranslates.right;
    let [bx, by] = sideTranslates.bottom;
    let [px, py] = parentTranslate;

    let highlights;

    if ('tb'.includes(sideFrom)) {
      highlights = tools.g([
        tools.operationIdText(operationId, x + w / 2, y + h / 2),
        tools.line( // center line parallel to x
          -px / 1.5, y, partX + px / 1.5, y, {
          stroke: 'black',
          'stroke-width': '1px',
          'stroke-dasharray': '8 4',
        }
        ),
        tools.line( // central line parallel to y
          -px / 1.5, y + h, partX + px / 1.5, y + h, {
          stroke: 'black',
          'stroke-width': '1px',
          'stroke-dasharray': '8 4',
        }
        ),
        tools.rect(
          partZ - z, y, z, h, {
          fill: tools.pazBlind,
          'stroke-width': 0,
          transform: `translate(${-px + lx}, ${-px + ly})`
        }
        ),
        tools.rect(
          0, y, z, h, {
          fill: tools.pazBlind,
          'stroke-width': 0,
          transform: `translate(${-px + rx}, ${-px + ry})`
        }
        ),
      ], { transform: `translate(${parentTranslate.join(', ')})` })
    } else if ('rl'.includes(sideFrom)) {
      highlights = tools.g([
        tools.operationIdText(operationId, x + w / 2, y + h / 2),
        tools.line(
          x, -py / 1.5, x, partY1 + py / 1.5, {
          stroke: 'black',
          'stroke-width': '1px',
          'stroke-dasharray': '8 4',
        }
        ),
        tools.line(
          x + w, -py / 1.5, x + w, partY + py / 1.5, {
          stroke: 'black',
          'strokw-width': '1px',
          'stroke-dasharray': '8 4',
        }
        ),
        tools.rect(
          x, partZ - z, w, z, {
          fill: tools.pazBlind,
          'stroke-width': 0,
          transform: `translate(${-px + tx}, ${-py + ty})`,
        }),
        tools.rect(
          x, 0, w, z, {
          fill: tools.pazBlind,
          'stroke-width': 0,
          transform: `translate(${-px + bx} ${-py + by})`
        }
        )
      ], { transform: `translate(${parentTranslate.join(', ')})` })
    }

    return tools.g([highlights, pazTable], { id: pazId })
  },
  pazInfoTable(
    d1, partX1, partY1, partZ, z, sideFrom, side, wPaz, dSideStart, dSideEnd,
    typePaz, translateFunc, operationId, pazId, startPoint
  ) {
    let T = translateFunc;
    let side_translations = {
      l: translateLib.PAZ_SIDE_L,
      r: translateLib.PAZ_SIDE_R,
      b: translateLib.PAZ_SIDE_B,
      t: translateLib.PAZ_SIDE_T,
      f: translateLib.PAZ_SIDE_F,
      bb: translateLib.PAZ_SIDE_BB,
    };
    let side_from_translations = {
      l: [translateLib.PAZ_D_FROM_L, translateLib.PAZ_D_FROM_R],
      r: [translateLib.PAZ_D_FROM_R, translateLib.PAZ_D_FROM_L],
      t: [translateLib.PAZ_D_FROM_T, translateLib.PAZ_D_FROM_B],
      b: [translateLib.PAZ_D_FROM_B, translateLib.PAZ_D_FROM_T],
    }
    const sideFromTKeys = side_from_translations[sideFrom];
    let opSideLength = null;

    if (['f', 'bb'].includes(side)) {
      if ('rl'.includes(sideFrom))
        opSideLength = partX1;
      else
        opSideLength = partY1;
    } else if ('rl'.includes(side)) {
      if ('tb'.includes(sideFrom)) 
        opSideLength = partY1;
      else 
        opSideLength = partZ;
    } else if ('tb'.includes(side)) {
      if ('tb'.includes(sideFrom)) 
        opSideLength = partZ;
      else 
        opSideLength = partX1;
    }

    let d1OpSide = opSideLength - d1 - wPaz; // indent from opposite side

    if (Number.isInteger(d1OpSide))
      d1OpSide = Math.trunc(d1OpSide);

    let fontSize = 15;
    side = side_translations[side];
    let closedPaz = typePaz === 0;
    let pazType = (
      closedPaz
        ? translateLib.PAZ_TYPE_CLOSED
        : translateLib.PAZ_TYPE_OPEN
    );
    [d1, z, wPaz, dSideStart, dSideEnd] = [d1, z, wPaz, dSideStart, dSideEnd].map(a => tools.rnd(a));
    let startPointTspan = tools.getStartPointTspan(startPoint, T);
    return tools.operationTable([
      tools.text(
        (
          operationId !== null
            ? tools.operationIdTspan(`ID: ${operationId}`)
            : ''
        )
        + tools.tspan(
          T(translateLib.PAZ_POPUP_NAME), {
          x: 0,
          dy: operationId !== null ? '1.3em' : ''
        })
        + tools.tspan(
          `${T(translateLib.PAZ_TYPE)}: ${T(pazType)}`, { x: 0, dy: '1.2em' }
        )
        + tools.tspan(
          `${T(sideFromTKeys[0])}: ${d1}`, { x: 0, dy: '1.2em' }
        )
        + tools.tspan(
          `${T(sideFromTKeys[1])}: ${d1OpSide}`, { x: 0, dy: '1.2em' }
        )
        + tools.tspan(
          `${T(translateLib.PAZ_Z)}: ${z}`, { x: 0, dy: '1.2em' }
        )
        + tools.tspan(
          `${T(translateLib.PAZ_SIDE)}: ${T(side)}`, { x: 0, dy: '1.2em' }
        )
        + tools.tspan(
          `${T(translateLib.PAZ_W_PAZ)}: ${wPaz}`, { x: 0, dy: '1.2em' }
        )
        + (
          closedPaz
            ? (
              tools.tspan(
                `${T(translateLib.PAZ_D_SIDE_START)}: ${dSideStart}`, { x: 0, dy: '1.2em' }
              )
              + tools.tspan(
                `${T(translateLib.PAZ_D_SIDE_END)}: ${dSideEnd}`, { x: 0, dy: '1.2em' }
              )
            )
            : '')
        + startPointTspan
        , { 'font-size': `${fontSize}px` }
      )
    ], { operation_id_bg: true, table_id: pazId })
  },
  pazes(
    pazes, partX, partY, partX1, partY1, partZ, parentTranslate,
    sideTranslates, measurementOffset, partSizeType, params = {}
  ) {
    let defaultParams = {
      language: 'en',
      partSide: null,
      onSide: false,
      typeSvg: null
    };
    params = { ...defaultParams, ...params };
    let pazStrokeWidth;

    if (params.typeSvg === 'lbl') {
      pazStrokeWidth = '10px';
    } else {
      pazStrokeWidth = '1px';
    }

    let a = '';
    let hovers = '';
    let measurementsCoords = [];
    let translateFunc = translateLib.translate(params.language);

    for (let paz of pazes) {
      let operationId = paz.id;
      let startPoint = paz.start_point;
      let d = paz.d;
      let d1 = paz.d1;
      let dSideEnd = parseFloat(paz.d_side_end || 0);
      let dSideStart = parseFloat(paz.d_side_start || 0);
      let side = paz.side;
      let sideFrom = paz.side_from;
      let typePaz = parseFloat(paz.type_paz);
      let w = parseFloat(paz.w);
      let wPaz = parseFloat(paz.w_paz);
      let z = parseFloat(paz.z)

      let pazId = `paz_${operationId}`;

      let pazTable = this.pazInfoTable(
        d1, partX1, partY1, partZ, z, sideFrom, side, wPaz, dSideStart, dSideEnd, typePaz,
        translateFunc, operationId, pazId, startPoint
      );

      if (params.onSide) {
        let sidePaz = this.onSidePaz(
          d, d1, z, side, sideFrom, typePaz, w, wPaz, dSideStart, dSideEnd,
          partX, partY, partX1, partY1, partZ, params.partSide, pazId, partSizeType,
          { 'stroke-width': pazStrokeWidth }
        );
        a += sidePaz[0];
        let forHover = sidePaz[1];

        if (forHover === null) continue;

        hovers += this.pazInfoHighlight(
          ...forHover, parentTranslate, sideTranslates, pazId, pazTable,
          operationId
        );

        let coords = this.pazMeasurementCoords(
          ...forHover, side, parentTranslate, sideTranslates,
          measurementOffset, pazId
        );
        measurementsCoords = measurementsCoords.concat(coords);
      } else {
        let vPaz = this.verticalPaz(
          d, d1, z, side, sideFrom, typePaz, w, wPaz, dSideStart, dSideEnd,
          partX, partY, partX1, partY1, partZ, pazId, partSizeType,
          { 'stroke-width': pazStrokeWidth }
        );
        // v_paz[0]: (x, y, x1, y1, z, w, h, part_x, part_y, part_x1, part_y1, part_z, side_from)
        a += vPaz[0];

        hovers += this.pazInfoHighlight(
          ...vPaz[1], parentTranslate, sideTranslates, pazId, pazTable,
          operationId
        )

        let coords = this.pazMeasurementCoords(
          ...vPaz[1], side, parentTranslate, sideTranslates,
          measurementOffset, pazId
        );
        measurementsCoords = measurementsCoords.concat(coords);
      }
    }

    return [a, hovers, measurementsCoords]
  }
}
